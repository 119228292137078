import React, { useState } from "react";
import { CheckBox } from "./CheckBox";
import Icon from "./Icon.js";
import "./MainToggleMenu.scss";

const MainToggleMenu = ({
  setToggleCheckedFrivillig,
  setToggleCheckedNewPartners,
  toggleCheckedFrivillig,
  toggleCheckedNewPartners,
  orgnumber,
  includeLargeEmissions,
  setIncludeLargeEmissions,
  checkIfLargeEmissions,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div className="main-toggle-component">
      <div className="main-toggle-dropdown-component-container" >
        <span onClick={() => setMenuVisible(!menuVisible)}>Andre datavisningsvalg</span>
        <button
          className="closing-button"
          onClick={() => {
            setMenuVisible(!menuVisible);
          }}
        >
          {menuVisible ? (
            <Icon name="triangle_up" />
          ) : (
            <Icon name="triangle_down" />
          )}
        </button>
      </div>
      {menuVisible && (
        <div className="main-toggle-menu-container">
          <div className="main-toggle-content">
            <div className="main-toggle-inner-content checkbox">
              <CheckBox
                onCheckChange={(newChecked) => {
                  setToggleCheckedFrivillig(newChecked);
                }}
                checked={toggleCheckedFrivillig}
                label="Inkluder frivillige utslippstyper"
              />
              {orgnumber ? (
                ""
              ) : (
                <CheckBox
                  onCheckChange={(newChecked) => {
                    setToggleCheckedNewPartners(newChecked);
                  }}
                  checked={toggleCheckedNewPartners}
                  label="Inkluder nye klimapartnere"
                />
              )}
              {checkIfLargeEmissions !== null &&
                checkIfLargeEmissions !== undefined && (
                  <CheckBox
                    onCheckChange={(newChecked) => {
                      setIncludeLargeEmissions(newChecked);
                    }}
                    checked={includeLargeEmissions}
                    label="Inkluder store prosessutslipp"
                  />
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainToggleMenu;
