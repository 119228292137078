import React from "react";
import ChartContainer from "../components/charts/ChartContainer.js";
import { formatReturnValue } from "../utils/number";
import "./KeyResultContainer.scss";

const getTotalValue = (newValue, oldValue) => {
  if (oldValue === 0 || oldValue === null || newValue === null) {
    return 0;
  }
  if (oldValue === null || newValue === null) {
    return undefined;
  }
  return ((newValue - oldValue) / oldValue) * 100;
};

const KeyResultContainer = ({
  data,
  compareToEarlierYear,
  className,
  pdfTitle,
  toggleCheckedFrivillig,
}) => {
  if (!data || data.length === 0) {
    return null;
  }
  const year1 = data[0].year1;
  const year2 = data[0].year2;

  // Scope 1

  const scope1Value = toggleCheckedFrivillig
    ? data[0].total_scope_year2
    : data[0].total_scope_uf_year2;

  const scope1ValueYear1 = toggleCheckedFrivillig
    ? data[0].total_scope_year1
    : data[0].total_scope_uf_year1;

  const scope1Difference = getTotalValue(scope1Value, scope1ValueYear1);

  // Scope 2

  const scope2Value = toggleCheckedFrivillig
    ? data[4].total_scope_year2
    : data[4].total_scope_uf_year2;

  const scope2ValueYear1 = toggleCheckedFrivillig
    ? data[4].total_scope_year1
    : data[4].total_scope_uf_year1;

  const scope2Difference = getTotalValue(scope2Value, scope2ValueYear1);

  // Scope 3

  const scope3Value = toggleCheckedFrivillig
    ? data[8].total_scope_year2
    : data[8].total_scope_uf_year2;

  const scope3ValueYear1 = toggleCheckedFrivillig
    ? data[8].total_scope_year1
    : data[8].total_scope_uf_year1;

  const scope3Difference = getTotalValue(scope3Value, scope3ValueYear1);

  // Include sum

  const totalScopeValue = toggleCheckedFrivillig
    ? data[0].total_year2
    : data[0].total_uf_year2;

  const totalScopeValueYear1 = toggleCheckedFrivillig
    ? data[0].total_year1
    : data[0].total_uf_year1;

  const totalScopeDifference = getTotalValue(
    totalScopeValue,
    totalScopeValueYear1
  );

  return (
    <ChartContainer title={"Nøkkelresultat " + year2} className={className}>
      <div className="key-result-content">
        <div className="key-result-inner-content">
          <p className="key-result-sub-title">Utslipp scope 1</p>
          <p className="key-result-number">
            {scope1Value !== undefined &&
            scope1Value !== null &&
            scope1Value !== 0
              ? formatReturnValue(scope1Value / 1000)
              : "-"}
          </p>
          {compareToEarlierYear && (
            <>
              <p className="key-result-sub-title">Differanse fra {year1}</p>
              <p className="key-result-number">
                {scope1Difference !== undefined &&
                scope1Difference !== null &&
                scope1Difference !== 0
                  ? formatReturnValue(scope1Difference) + " %"
                  : "-"}
              </p>
            </>
          )}
        </div>

        <div className="key-result-inner-content">
          <p className="key-result-sub-title">Utslipp scope 2</p>
          <p className="key-result-number">
            {scope2Value !== undefined &&
            scope2Value !== null &&
            scope2Value !== 0
              ? formatReturnValue(scope2Value / 1000)
              : "-"}
          </p>
          {compareToEarlierYear && (
            <>
              <p className="key-result-sub-title">Differanse fra {year1}</p>
              <p className="key-result-number">
                {scope2Difference !== undefined &&
                scope2Difference !== null &&
                scope2Difference !== 0
                  ? formatReturnValue(scope2Difference) + " %"
                  : "-"}
              </p>
            </>
          )}
        </div>

        <div className="key-result-inner-content">
          <p className="key-result-sub-title">Utslipp scope 3</p>
          <p className="key-result-number">
            {scope3Value !== undefined &&
            scope3Value !== null &&
            scope3Value !== 0
              ? formatReturnValue(scope3Value / 1000)
              : "-"}
          </p>
          {compareToEarlierYear && (
            <>
              {" "}
              <p className="key-result-sub-title">Differanse fra {year1}</p>
              <p className="key-result-number">
                {scope3Difference !== undefined &&
                scope3Difference !== null &&
                scope3Difference !== 0
                  ? formatReturnValue(scope3Difference) + " %"
                  : "-"}
              </p>
            </>
          )}
        </div>

        <div className="key-result-inner-content">
          <p className="key-result-sub-title">Total utslipp</p>
          <p className="key-result-number">
            {totalScopeValue !== undefined &&
            totalScopeValue !== null &&
            totalScopeValue !== 0
              ? formatReturnValue(totalScopeValue / 1000)
              : "-"}
          </p>
          {compareToEarlierYear && (
            <>
              <p className="key-result-sub-title">Differanse fra {year1}</p>
              <p className="key-result-number">
                {totalScopeDifference !== undefined &&
                totalScopeDifference !== null &&
                totalScopeDifference !== 0
                  ? formatReturnValue(totalScopeDifference) + " %"
                  : "-"}
              </p>
            </>
          )}
        </div>
      </div>
      {pdfTitle ? (
        ""
      ) : (
        <p className="key-result-footnote">
          * Oppgitt i tonn CO<sub>2</sub>-ekvivalenter
        </p>
      )}
    </ChartContainer>
  );
};

export default KeyResultContainer;
