import React from "react";

import "./Page.scss";

const Page = ({ children, className }) => {
  return (
    <div className={["pdf-page", className ? className : ""].join(" ")}>
      {children}
    </div>
  );
};

export default Page;
