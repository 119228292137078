import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";

export default function useData(
  groupid,
  orgnumber = null,
  scope = null,
  eltype = 2,
  toyear = 2021,
  includeFrivillig = false, // maybe true?
  onlyFrivillig = false,
  skip_new_members = false,
  filter = false
) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (groupid === "0" || groupid === "-") {
      return;
    }
    setData(null);
    setLoading(true);

    const req = orgnumber
      ? request
        // Vær obs
        .get(`${config.apiUrl}/getCompanyData`)
        .query({ orgnumber: orgnumber })
        .query({ toyear: toyear })
        .query({ eltype: eltype })
        .query({ filter: filter })
      : request
        .get(`${config.apiUrl}/getPartnersData`)
        .query({ skip_new_members: skip_new_members })
        .query({ groupid: groupid })
        .query({ toyear: toyear })
        .query({ eltype: eltype })
        .query({ filter: filter });

    req
      // .then((res) => res.body.rows)
      .then((res) => res.body)
      .then((res) => {
        // var filteredData = res;
        var filteredData = res.rows;

        filteredData = filteredData.filter(
          (data) =>
            data.kategori_navn !== "Annet (innkjøpte varer og tjenester)"
        );

        if (!includeFrivillig) {
          filteredData = filteredData.filter(
            (data) =>
              data.kategori_id !== 17 &&
              data.kategori_id !== 16 &&
              data.kategori_id !== 15 &&
              data.kategori_id !== 14
          );
        }

        if (onlyFrivillig) {
          filteredData = filteredData.filter(
            (data) =>
              data.kategori_id === 17 ||
              data.kategori_id === 16 ||
              data.kategori_id === 15 ||
              data.kategori_id === 14
          );
        }

        if (scope) {
          filteredData = filteredData.filter(
            (data) => data.scope === (scope === "frivillig" ? 3 : scope)
          );
        }
        // setData(filteredData);
        setData({rows: filteredData, budget: res.data && res.data.budget, attachments: res.data && res.data.attachments});
        setLoading(false);
      })
      .catch((err) => {
        console.error(err, "err");
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [
    eltype,
    toyear,
    groupid,
    scope,
    orgnumber,
    includeFrivillig,
    onlyFrivillig,
    skip_new_members,
    filter,
  ]);

  return [data, loading];
}
