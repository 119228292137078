import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";
export default function useDataNational(
  skip_new_members = false,
  toyear = 2021,
  eltype = 2,
  includeFrivillig = true,
  nationalActive = false,
  filter = false
) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!nationalActive) {
      return;
    }
    setData(null);
    setLoading(true);

    const req = request
      .get(`${config.apiUrl}/getPartnersDataMulti`)
      .query({ skip_new_members: skip_new_members })
      .query({ groupid: [1, 2, 3, 4, 5, 6, 7, 8] })
      .query({ toyear: toyear })
      .query({ eltype: eltype })
      .query({ filter: filter });
      
    req
      .then((res) => res.body.rows)
      .then((res) => {
        var filteredData = res;

        filteredData = filteredData.filter(
          (data) =>
            data.kategori_navn !== "Annet (innkjøpte varer og tjenester)"
        );
        if (!includeFrivillig) {
          filteredData = filteredData.filter((data) => !data.frivillig);
        }
        setData(filteredData);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [
    eltype,
    toyear,
    skip_new_members,
    includeFrivillig,
    nationalActive,
    filter,
  ]);

  return [data, loading];
}
