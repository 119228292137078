import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";

export default function useCompanies(groupid, year) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!groupid || groupid === -1 || groupid === 0) return;
    setData(null);
    setLoading(true);

    const req = request
      .get(`${config.apiUrl}/getCompanies`)
      .query({ groupid: groupid })
      .query({ year: year });

    req
      .then((res) => res.body.rows)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [groupid, year]);

  return [data, loading];
}
