import React, { useMemo } from "react";
import { formatReturnValue } from "../utils/number";
import ChartContainer from "./charts/ChartContainer";
import "./TotalSumContainer.scss";

const TotalSumContainer = ({
  data,
  toggleCheckedFrivillig,
  PDF,
  orgnumber,
  isNational,
}) => {
  const totalScopePurple = useMemo(() => {
    if (!(data && data.length)) return;

    let value = toggleCheckedFrivillig
      ? data[0].total_year0
      : data[0].total_uf_year0;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);
  const totalScopePurpleTitle = data && data.length > 0 ? data[0].year0 : "";

  const totalScopePink = useMemo(() => {
    if (!(data && data.length)) return;

    let value = toggleCheckedFrivillig
      ? data[0].total_year1
      : data[0].total_uf_year1;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);
  const totalScopePinkTitle = data && data.length > 0 ? data[0].year1 : "";

  const totalScopeYellow = useMemo(() => {
    if (!(data && data.length)) return;

    let value = toggleCheckedFrivillig
      ? data[0].total_year2
      : data[0].total_uf_year2;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);
  const totalScopeYellowTitle = data && data.length > 0 ? data[0].year2 : "";

  return (
    <ChartContainer
      title={
        orgnumber
          ? "Totalutslipp per år for virksomheten"
          : isNational
          ? "Totalutslipp per år nasjonalt"
          : "Totalutslipp per år for regionen"
      }
    >
      {PDF ? (
        ""
      ) : (
        <p className="climate-vision-subtitle">
          Her kan du se summen av alle scope for hvert år i tonn CO<sub>2</sub>
          -ekvivalenter.
        </p>
      )}
      <div className="data-circle-container">
        <div className="data-circle-inner">
          <div className="data-circle _2018">
            {data && data.length !== 0 && totalScopePurple !== 0
              ? formatReturnValue(totalScopePurple)
              : "-"}
          </div>
          <p>{totalScopePurpleTitle}</p>
        </div>
        <div className="data-circle-inner">
          <div className="data-circle _2019">
            {data && data.length !== 0 && totalScopePink !== 0
              ? formatReturnValue(totalScopePink)
              : "-"}
          </div>
          <p>{totalScopePinkTitle}</p>
        </div>
        <div className="data-circle-inner">
          <div className="data-circle _2020">
            {data && data.length !== 0 && totalScopeYellow !== 0
              ? formatReturnValue(totalScopeYellow)
              : "-"}
          </div>
          <p>{totalScopeYellowTitle}</p>
        </div>
      </div>
    </ChartContainer>
  );
};

export default TotalSumContainer;
