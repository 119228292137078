import React from "react";
import KeyBusinessContainer from "../containers/KeyBusinessContainer";
import KeyBusinessContainerGreen from "../containers/KeyBusinessContainerGreen";
import KeyResultContainer from "../containers/KeyResultContainer";
import ClimateVisionText from "../components/ClimateVisionText";
import BarChartContainer from "../components/charts/BarChartContainer";
import TotalSumContainer from "../components/TotalSumContainer";
import PieChartContainer from "../components/charts/PieChartContainer";
import ClimateVisionIconBox from "../components/ClimateVisionIconBox";
import Img from "../components/Image";
import config from "../config/index";
import "./FrontPage.scss";
import TaksonomiContainer from "../containers/TaksonomiContainer";

function createTextBox(orgnumber, textContent, columnName) {
  const createTextBox = orgnumber
    ? textContent && textContent[columnName] && textContent[columnName].replace(/<p><\/p>/i, "")
      ? true
      : false
    : true;
  return createTextBox;
}

const BusinessView = ({
  orgnumber,
  toggleCheckedFrivillig,
  textContent,
  data,
  pieChartactiveCf,
  currentYear,
}) => {
  const createMaalutdyping = createTextBox(
    orgnumber,
    textContent,
    "maalutdyping"
  );

  const createKommentar = createTextBox(orgnumber, textContent, "kommentar");

  const createMiljosertifisering = createTextBox(
    orgnumber,
    textContent,
    "miljosertifisering"
  );

  const createKlimavisjon = createTextBox(
    orgnumber,
    textContent,
    "klimavisjon"
  );

  const createSpydspiss = createTextBox(
    orgnumber,
    textContent,
    "spydspissprosjekter"
  );

  const createBestePraksis = createTextBox(
    orgnumber,
    textContent,
    "bestepraksis"
  );

  const createKlimatiltak = createTextBox(
    orgnumber,
    textContent,
    "klimatiltak"
  );

  const createTjenesterProdukter = createTextBox(
    orgnumber,
    textContent,
    "tjenesterprodukter"
  );

  const createKommunikasjon = createTextBox(
    orgnumber,
    textContent,
    "kommunikasjon"
  );

  return (
    <div>
      <Img
        src={`${
          config.apiUrl
        // }/getimagecompany.ashx?orgnumber=${orgnumber}&year=${currentYear}&imageid=${1}`} // imageid is hardcoded since there is only one image
        }/getcompanyimage?orgnumber=${orgnumber}`}
        className="region-partner-img"
        alt=""
      />

      {data && data.length !== 0 && (
        <KeyBusinessContainerGreen
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      {data && data.length !== 0 && (
        <KeyBusinessContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      {data && data.length !== 0 && <TaksonomiContainer data={data} />}
      {createKlimavisjon && (
        <ClimateVisionText
          textContent={textContent}
          title={[`Klimavisjon for ${currentYear}`]}
          rowName={["klimavisjon"]}
        />
      )}
      <ClimateVisionIconBox
        title={`Klimamål for ${currentYear}`}
        text={
          "Grønne symbol betyr at virksomheten har satt seg gjeldene mål, har etablert gjeldende plan eller oppnådd miljøsertifisering. Oransje symbol betyr at virksomheten ikke har det."
        }
        textContent={textContent}
      />
      {createMaalutdyping && (
        <ClimateVisionText
          textContent={textContent}
          title={["Utdyping klimamål"]}
          rowName={["maalutdyping"]}
        />
      )}
      {createKommentar && (
        <ClimateVisionText
          textContent={textContent}
          title={["Kommentar til årets klimaregnskap"]}
          rowName={["kommentar"]}
        />
      )}
      {data && data.length !== 0 && (
        <KeyResultContainer
          data={data}
          compareToEarlierYear={true}
          pdfTitle={false}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      <BarChartContainer
        data={data}
        toggleCheckedFrivillig={toggleCheckedFrivillig}
      />
      {data && data.length !== 0 && (
        <TotalSumContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
          orgnumber={orgnumber}
        />
      )}

      <PieChartContainer activeCrossfilter={pieChartactiveCf} />
      <ClimateVisionIconBox
        textContent={textContent}
        title={"Antall kjøretøy"}
      />

      {createSpydspiss && (
        <ClimateVisionText
          textContent={textContent}
          title={[`Spydspissprosjekter i ${currentYear}`]}
          rowName={["spydspissprosjekter"]}
        />
      )}
      {createBestePraksis && (
        <ClimateVisionText
          textContent={textContent}
          title={["Beste praksis "]}
          rowName={["bestepraksis"]}
        />
      )}

      {createKlimatiltak && (
        <ClimateVisionText
          textContent={textContent}
          title={[`Gjennomførte klimatiltak for ${currentYear}`]}
          rowName={["klimatiltak"]}
        />
      )}
      {createTjenesterProdukter && (
        <ClimateVisionText
          textContent={textContent}
          title={["Tjenester og produkter"]}
          rowName={["tjenesterprodukter"]}
        />
      )}
      {createKommunikasjon && (
        <ClimateVisionText
          textContent={textContent}
          title={["Kommunikasjon av klimaarbeid"]}
          rowName={["kommunikasjon"]}
        />
      )}
      {createMiljosertifisering && (
        <ClimateVisionText
          textContent={textContent}
          title={["Miljøsertifisering"]}
          rowName={["miljosertifisering"]}
        />
      )}
    </div>
  );
};
export default BusinessView;
