import React, { useState, useEffect } from "react";
import "./PopUpPDF.scss";
import Icon from "../Icon.js";
import download_all from "../../static/pdf/download_all.png";
import download from "../../static/pdf/download_single.png";
import LoadMask from "../LoadMask";

const PopUpPDF = ({
  showPdfPopup,
  orgnumber,
  setLoading,
  loading,
  setPDFType,
  PDFType,
  onPrint,
  totalData,
  data,
  isReady,
}) => {
  const [onPrintClicked, setOnPrintClicked] = useState(false);
  useEffect(() => {
    if (!onPrintClicked) {
      return;
    }
    if (onPrintClicked && PDFType === "all" && totalData) {
      setOnPrintClicked(false);
      onPrint();
    }
    if (onPrintClicked && PDFType === "single" && data) {
      setOnPrintClicked(false);
      onPrint();
    }
  }, [data, totalData, onPrintClicked, PDFType, onPrint]);

  return (
    <>
      {!isReady && <LoadMask loading={loading} />}
      <div className="popup">
        <div className="popup_inner">
          <div className="popup-banner-top">
            <button
              className="popup-close-btn"
              onClick={() => showPdfPopup(false)}
            >
              <Icon name="cross" />
            </button>
          </div>
          <div className="popup-textbox">
            <p className="popup-pdf-title">
              Hvilken rapport ønsker du å hente?
            </p>
            <p>
              Her kan du velge om du vil laste ned en komplett oversikt over
              alle bedriftene i denne regionen, eller kun den valgte bedriften.
              Husk å velge å ha bakgrunnsgrafikk og margin 0 i printmenyen. NB.
              Filen kan være veldig stor og derfor kan det ta lang tid før
              menyen dukker opp.
            </p>
          </div>
          <div className="popup-btn-group">
            <button
              className="export-pdf-btn all"
              onClick={() => {
                setLoading(true);
                setPDFType("all");
                setOnPrintClicked(true);
              }}
            >
              <img src={download_all} className="pdf-btn-img" alt="" />
              <p>Hent fullstendig oversikt</p>
            </button>
            <button
              className="export-pdf-btn single"
              onClick={() => {
                setLoading(true);
                setPDFType("single");
                setOnPrintClicked(true);
              }}
            >
              <img src={download} className="pdf-btn-img" alt="" />
              <p>Hent kun for denne {orgnumber ? "bedriften" : "regionen"}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopUpPDF;
