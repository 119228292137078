import React from "react";
import config from "../config/index.js";
import KeyBusinessContainer from "../containers/KeyBusinessContainer";
import KeyResultContainer from "../containers/KeyResultContainer";
import AggregatedClimateVision from "../components/AggregatedClimateVision";
import BarChartContainer from "../components/charts/BarChartContainer";
import TotalSumContainer from "../components/TotalSumContainer";
import PieChartContainer from "../components/charts/PieChartContainer";
import Img from "../components/Image.js";
import "./FrontPage.scss";

const NationalView = ({
  groupId,
  toggleCheckedFrivillig,
  textContent,
  data,
  pieChartactiveCf,
  currentYear,
}) => {
  return (
    <>
      <Img
        src={`${
          config.apiUrl
        // }/getimagenetwork.ashx?networkid=${1}&year=${currentYear}&imageid=${1}`} // networkid and imageid are hardcoded since there is only one image
      }/getnetworkimage`}
        className="region-partner-img"
        alt=""
      />
      {data && data.length !== 0 && (
        <KeyBusinessContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      {textContent && data && data.length !== 0 && (
        <AggregatedClimateVision
          isNational={true}
          textContent={textContent}
          totalCompaniesInRegion={data[0].antall_bedrifter_year2}
        />
      )}

      {data && data.length !== 0 && (
        <KeyResultContainer
          data={data}
          compareToEarlierYear={true}
          pdfTitle={false}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}

      <BarChartContainer
        data={data}
        toggleCheckedFrivillig={toggleCheckedFrivillig}
      />
      {data && data.length !== 0 && (
        <TotalSumContainer
          data={data}
          isNational={true}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}

      <PieChartContainer activeCrossfilter={pieChartactiveCf} />
    </>
  );
};

export default NationalView;
