import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";

export default function usePartnerCompanyData(
  groupid,
  eltype,
  toyear,
  toggleCheckedFrivillig,
  skip_new_members,
  filter,
  doLoad,
) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setData(null);
    setLoading(true);

    if (!groupid || groupid === "-" || groupid === "0" || !doLoad) return;

    const req = request
      .get(`${config.apiUrl}/getPartnersDataCompany`)
      .query({ skip_new_members: skip_new_members })
      .query({ groupid: groupid })
      .query({ toyear: toyear })
      .query({ eltype: eltype })
      .query({ filter: filter });
      
    req
      .then((res) => res.body)
      .then((res) => {
        let filteredData = undefined;
        if (!toggleCheckedFrivillig) {
          filteredData = res.rows.map(function (element) {
            return {
              ...element,
              bidrag: element.bidrag.filter((d) => {
                if (
                  d.kategori_id !== 17 &&
                  d.kategori_id !== 16 &&
                  d.kategori_id !== 15 &&
                  d.kategori_id !== 14 &&
                  d.kategori_navn !== "Annet (innkjøpte varer og tjenester)"
                ) {
                  return d;
                }
                return d;
              }),
            };
          });
          res.rows = filteredData;
        }
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [
    eltype,
    toyear,
    groupid,
    toggleCheckedFrivillig,
    skip_new_members,
    filter,
    doLoad,
  ]);

  return [data, loading];
}
