import React from "react";
import useTextFromAPI from "../hooks/useTextFromAPI";
import dompurify from "dompurify";
import "./FAQ.scss";
import LoadingIndicator from "./LoadingIndicator";

const FAQ = () => {
  const [data, loading] = useTextFromAPI(true, false, 1, 2021);
  var sanitizedFAQText = "";

  if (data && data.faq) {
    sanitizedFAQText = dompurify.sanitize(data.faq);
  }
  return (
    <div className="faq--wrapper">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <header className="faq--title">
            <h2>FAQ</h2>
          </header>
          {data?.faq && (
            <div dangerouslySetInnerHTML={{ __html: sanitizedFAQText }} />
          )}
        </>
      )}
    </div>
  );
};

export default FAQ;
