import React, { useMemo } from "react";
import config from "../../config/index";
import PDFChartContainer from "./PDFChartContainer";
import { IntroductionPage, CoverPage, MethodPage } from "./PDFexporter";
import "./PDFexporter.scss";
import Page from "./Page.js";
import Document from "./Document.js";
import PDFexportSingle from "./PDFexportSingle";
import PDFexportSingleBuisimness from "./PDFexportSingleBuisiness";
import useCrossfilter from "../../hooks/useCrossfilter";
import { AVIDashboard } from "avinet-dashboard-lib/lib/Context/AVIDashboard/AVIDashboard";

const PDFexport = ({
  titlePage,
  groupId,
  orgnumber,
  PDFType,
  data,
  totalData,
  budgetData,
  textContent,
  toggleCheckedFrivillig,
  setIsReady,
  currentYear,
}) => {
  const description = useMemo(() => {
    return orgnumber
      ? textContent &&
          textContent["omvirksomheten"] &&
          textContent["omvirksomheten"]
      : config.omRegionenText[groupId];
  }, [groupId, orgnumber, textContent]);

  const cf = useCrossfilter(data, "pdfCharts");

  return (
    <Document className="printable-document">
      <AVIDashboard
        chartGroup="pdfCharts"
        crossfilter={cf}
        onChartsInitialized={() => {
          setIsReady(true);
        }}
      >
        <Page className="margin-free">
          {PDFType === "all" ? (
            <CoverPage
              title={config.regionId[groupId]}
              subTitle={"Klimapartnere"}
              year={currentYear}
            />
          ) : (
            <CoverPage
              title={titlePage}
              subTitle={"Klimapartnere"}
              year={currentYear}
            />
          )}
        </Page>
        <Page>
          <MethodPage />
        </Page>

        {PDFType === "all" ? (
          totalData &&
          totalData.rows.map((d, idx) => {
            var description = "";
            var title = "";
            if (idx !== 0) {
              title = d["orgnavn"];
              description = d["omvirksomheten"] && d["omvirksomheten"];
            } else {
              title = "Om virksomheten";
              description = config.omRegionenText[groupId];
            }
            return (
              <div key={idx}>
                <Page className="margin-free">
                  <IntroductionPage
                    title={title}
                    textContent={description}
                    large={description && description.length > 1800}
                  />
                </Page>
                <Page key={idx}>
                  <PDFChartContainer
                    groupId={groupId}
                    data={d.bidrag}
                    toggleCheckedFrivillig={toggleCheckedFrivillig}
                    cf={cf}
                  />
                </Page>
              </div>
            );
          })
        ) : orgnumber ? (
          <PDFexportSingleBuisimness
            groupId={groupId}
            description={description}
            toggleCheckedFrivillig={toggleCheckedFrivillig}
            data={data}
            budgetData={budgetData}
            orgnumber={orgnumber}
            cf={cf}
            textContent={textContent}
          />
        ) : (
          <PDFexportSingle
            groupId={groupId}
            description={description}
            toggleCheckedFrivillig={toggleCheckedFrivillig}
            data={data}
            cf={cf}
            textContent={textContent}
          />
        )}
      </AVIDashboard>
    </Document>
  );
};
export default PDFexport;
