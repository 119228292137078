import React from "react";
import PropTypes from "prop-types";

import "./Document.scss";

const Document = ({ children, className }) => {
  return (
    <div className={["pdf-document", className ? className : ""].join(" ")}>
      {children}
    </div>
  );
};

Document.propTypes = {
  children: PropTypes.node,
};

export default Document;
