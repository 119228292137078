import React, { useEffect, useState } from "react";
import dompurify from "dompurify";
import ChartContainer from "./charts/ChartContainer.js";
import carbonNeutralIconYellow from "../static/ikke-karbonneutral-klimapartner.png";
import carbonNeutralIconGreen from "../static/Karbonneutral-klimapartner.png";
import fossilFreeIconGreen from "../static/Fosilfri-klimapartner.png";
import fossilFreeIconYellow from "../static/ikke-fossilfri-klimapartner.png";
import config from "../config/index";
import Icon from "./Icon.js";

import "./ClimateVisionText.scss";

export const HoverableTooltip = ({ title, tooltipText }) => (
  <>
    {title && <p className="total-companies-title">{title}</p>}
    <span className="info-legend-tooltip">
      i
      <span className="info-legend-tooltip--triangle">
        <Icon name="triangle_up" />
      </span>
      <span className="info-legend-tooltip--text">{tooltipText}</span>
    </span>
  </>
);

const IconText = ({ imgSrc, imgAlt, text, children, className }) => (
  <div className="iconText--container">
    {children}
    <img className="cvt-icon" src={imgSrc} alt={imgAlt} />
    <p className={`total-companies-title ${className}`}>{text}</p>
  </div>
);

const ClimateVisionText = ({
  textContent,
  title,
  regionText,
  totalCompaniesInRegion,
  totalCompaniesScope0,
  orgnumber,
  rowName,
  className,
  fossilFree,
  carbonNeutralQuotas,
  attachments,
}) => {
  const [, setShowMore] = useState();
  useEffect(() => {
    if (regionText) setShowMore(false);
  }, [regionText]);
  return (
    <>
      <ChartContainer title={title} className={className}>
        {regionText ? (
          <div className="climate-vision-text--container">
            <p
              className="climate-vision-subtext"
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(regionText, { ADD_ATTR: ['target'] }),
              }}
            />
          </div>
        ) : attachments && (attachments.length > 0) ? (
            <div className="climate-vision-text--container">
              {
                attachments?.map((a, idx) => (  
                  <p>
                    <a href={a.href} key={idx} target="_blank" rel="noopener noreferrer">{a.title}</a>
                  </p>
                ))
              }
            </div>
          ) : (
          rowName?.map((d, idx) => (
            <div className="climate-vision-text--container" key={idx}>
              <p
                className="climate-vision-subtext"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(textContent?.[d], { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
          )))
        }
        {orgnumber && (
          <div className="climate-vision-icon--container">
            <IconText
              imgSrc={fossilFree ? fossilFreeIconGreen : fossilFreeIconYellow}
              imgAlt={fossilFree ? "Fossilfri" : "Ikke fossilfri"}
              text={fossilFree ? "Fossilfri" : "Ikke fossilfri"}
            >
              <HoverableTooltip
                tooltipText={config.legend.fossilfri.description}
              />
            </IconText>

            {carbonNeutralQuotas !== null && (
              <IconText
                imgSrc={
                  carbonNeutralQuotas > 0
                    ? carbonNeutralIconGreen
                    : carbonNeutralIconYellow
                }
                imgAlt={
                  carbonNeutralQuotas > 0
                    ? "Klimakompensert"
                    : "Ikke klimakompensert"
                }
                text={
                  carbonNeutralQuotas > 0
                    ? "Klimakompensert"
                    : "Ikke klimakompensert"
                }
              >
                <HoverableTooltip
                  tooltipText={config.legend.karbonnoytral.description}
                />
              </IconText>
            )}
          </div>
        )}

        {totalCompaniesInRegion && !orgnumber && (
          <div className="climate-vision-icon--container">
            <IconText
              imgSrc={fossilFreeIconGreen}
              imgAlt="antall fossile"
              text={`${totalCompaniesScope0} / ${totalCompaniesInRegion}`}
              className={"number"}
            >
              <HoverableTooltip
                title="Antall fossilfrie partnere&nbsp;"
                tooltipText={config.legend.fossilfri.description}
              />
            </IconText>

            <IconText
              imgSrc={carbonNeutralIconGreen}
              imgAlt="antall klimakompenserte"
              text={`${carbonNeutralQuotas} / ${totalCompaniesInRegion}`}
              className={"number"}
            >
              <HoverableTooltip
                title={"Antall klimakompenserte partnere"}
                tooltipText={config.legend.karbonnoytral.description}
              />
            </IconText>
          </div>
        )}
      </ChartContainer>
    </>
  );
};
export default ClimateVisionText;
