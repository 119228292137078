import React, { useEffect, useMemo, useState } from "react";
import ChartContainer from "./ChartContainer.js";
import { RowChartStacked } from "avinet-dashboard-lib";
import { formatNumber } from "../../utils/number.js";
import * as d3 from "d3";

import "./BudgetChartContainer.scss";

const BudgetChartContainer = ({
  activeCrossfilter,
  isKlimaRegnskap,
  isPdf,
}) => {
  const [dim, setDim] = useState();
  const displayChart = isKlimaRegnskap ? "inactive" : "";

  useEffect(() => {
    if (!activeCrossfilter) return;

    const dimension = activeCrossfilter.dimension((d) => {
      return [d.year, d.type];
    });

    setDim(dimension);
    return () => dimension.dispose();
  }, [activeCrossfilter]);

  const group = useMemo(() => {
    if (!dim) return;

    return dim.group().reduceSum((d) => d.value);
  }, [dim]);

  return (
    <div className={"time-barchart-container " + displayChart}>
      <ChartContainer title="Klimabudsjett">
        <p className="time-barchart-subtitle">
          Før musepeker over fargeboks for å se hvilken utslippskilde den
          representerer. Alle verdier er oppgitt i tonn CO<sub>2</sub>
          -ekvivalenter.
        </p>
        {dim && (
          <RowChartStacked
            group={group}
            dimension={dim}
            gap={0.6}
            legendItemSymbol="circle"
            labelNumberFormat={(d) => formatNumber(d)}
            valueAccessor={(d) => d}
            subStackOrder={(a, b, c) => (a < b ? -1 : a > b ? 1 : 0)}
            showLegend
            legendCols={isPdf ? 2 : window.innerWidth < 478 ? 1 : 3}
            colors={d3
              .scaleOrdinal()
              .range([
                "#c6dbef",
                "#c7e9c0",
                "#e6550d",
                "#6baed6",
                "#fdae6b",
                "#9e9ac8",
                "#31a354",
                "#bcbddc",
                "#756bb1",
                "#dadaeb",
                "#a1d99b",
                "#003e74",
                "#fdd0a2",
                "#74c476",
                "#9ecae1",
                "#fd8d3c",
              ])
              .domain([
                "Andre reiser (tog/buss/taxi)",
                "Avfall til gjenvinning",
                "Bygg (frivillig)",
                "Drivstoff (kjøretøy og annet forbruk)",
                "Elektrisitet",
                "Fjernvarme/-kjøling",
                "Flyreiser",
                "Fyringsolje",
                "Km-godtgjørelse",
                "Pendling",
                "Propan/natur-/lystgass",
                "Prosessutslipp",
                "Reise og transport (frivillig)",
                "Restavfall til forbrenning",
                "Tjenester (frivillig)",
                "Varer (frivillig)",
              ])}
          />
        )}
      </ChartContainer>
    </div>
  );
};

export default BudgetChartContainer;
