import React from "react";
import KeyBusinessContainer from "../containers/KeyBusinessContainer";
import config from "../config/index.js";
import KeyResultContainer from "../containers/KeyResultContainer";
import AggregatedClimateVision from "../components/AggregatedClimateVision";
import BarChartContainer from "../components/charts/BarChartContainer";
import TotalSumContainer from "../components/TotalSumContainer";
import PieChartContainer from "../components/charts/PieChartContainer";
import Img from "../components/Image";
import "./FrontPage.scss";

const RegionView = ({
  groupId,
  toggleCheckedFrivillig,
  textContent,
  data,
  pieChartactiveCf,
  currentYear,
}) => {
  return (
    <div>
      <Img
        src={`${
          config.apiUrl
        // }/getimagegroup.ashx?groupid=${groupId}&year=${currentYear}&imageid=${1}`} // imageid is hardcoded since there is only one image
        }/getgroupimage?groupid=${groupId}`}
        className="region-partner-img"
        alt=""
      />

      {data && data.length !== 0 && (
        <KeyBusinessContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      {textContent && data && data.length !== 0 && (
        <AggregatedClimateVision
          isNational={false}
          textContent={textContent}
          totalCompaniesInRegion={data[0].antall_bedrifter_year2}
        />
      )}

      {data && data.length !== 0 && (
        <KeyResultContainer
          data={data}
          compareToEarlierYear={true}
          pdfTitle={false}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      <BarChartContainer
        data={data}
        toggleCheckedFrivillig={toggleCheckedFrivillig}
      />
      {data && data.length !== 0 && (
        <TotalSumContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}
      <PieChartContainer activeCrossfilter={pieChartactiveCf} />
    </div>
  );
};
export default RegionView;
