import React, { /**useEffect,**/ useState } from "react";
import SideMenu from "../components/SideMenu";
import Icon from "../components/Icon";
import "./MainLayout.scss";

export const MainLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  /**useEffect(() => {
    //window.scrollTo(0, 0);
  });**/

  return (
    <div>
      <a
        onFocus={() => "this.value='Returner til klimapartnere.no'"}
        value="Returner til Klimapartnere.no"
        href="https://klimapartnere.no/"
        className="main-layout-cross"
      >
        <Icon name="cross" />
      </a>

      <div
        className="sidemenu-hamburger"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <SideMenu
        className="sidemenu-container"
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
      <div className="page-layout--container">
        <div className="page-layout--components">{children}</div>
      </div>
      <footer></footer>
    </div>
  );
};
