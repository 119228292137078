import React from "react";
import dompurify from 'dompurify';
import "./ScopeBoxes.scss";

const ScopeBoxes = ({ img, title, text }) => {
  return (
    <div className="scopeboxes-container">
      <img className="scopeboxes-img" src={img} alt="" />
      <div className="print-boxes">
        <h5>{title}</h5>
        <p
          className={"climate-vision-transport-text small"}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(text),
          }}
        />
      </div>
    </div>
  );
};

export default ScopeBoxes;
