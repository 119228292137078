import React, { useState, useEffect } from "react";

const Img = ({ src, fallbackSrc, alt = "", ...otherProps }) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);

    const img = new Image();

    img.src = imgSrc;

    img.onload = () => setIsAvailable(true);

    img.onerror = () => {
      if (fallbackSrc) {
        setImgSrc(fallbackSrc);
        setIsAvailable(true);
      } else {
        setIsAvailable(false);
      }
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imgSrc, src, fallbackSrc]);

  return isAvailable && <img src={imgSrc} alt={alt} {...otherProps} />;
};

export default Img;
