import "./BarChartContainer.scss";

import * as d3 from "d3";

import React, { useEffect, useMemo, useState } from "react";

import { BarChartGrouped } from "avinet-dashboard-lib";
import ChartContainer from "./ChartContainer.js";
import SwitchToggle from "../SwitchToggle";
import TotalScopeContainer from "../TotalScopeContainer";
import useCrossfilter from "../../hooks/useCrossfilter.js";
import { formatNumber } from "../../utils/number";

// https://github.com/avinet/avinet-dashboard-lib
// https://github.com/avinet/kystdatahuset-dashboard/blob/ab534cc870c3ed75a81b5755d1713e0f79260fa1/src/views/DbrdPilotOffice/DbrdPilotOffice.js#L199
// https://github.com/avinet/avinet-dashboard-lib/blob/master/src/Charts/Bar/examples/BarChart.example.grouped.js

const YearLegend = ({ year1, year2, year3 }) => {
  return (
    <div className="year-legend-container">
      <div className="year-legend-inner-content">
        <div className="legend-color purple" />
        {year1}
      </div>
      <div className="year-legend-inner-content">
        <div className="legend-color pink" />
        {year2}
      </div>
      <div className="year-legend-inner-content">
        <div className="legend-color yellow" />
        {year3}
      </div>
    </div>
  );
};

const BarChartContainer = ({
  data,
  toggleCheckedFrivillig,
  title,
  margins = [10, 40, 90, 35],
}) => {
  const [dim, setDim] = useState();
  const [dataArr, setData] = useState([]);
  const [scope, setScope] = useState(1);
  const [tiltedLabels, setTiltedLabels] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length < 1) return;

    const filteredData = data.filter((v) => v.scope === scope);

    setFilteredData(filteredData);

    const arr0 = filteredData.map((d) => ({
      a: d.kategori_navn,
      //v: d.utslipp_year0 / 1000,
      v: Math.round(10 * d.utslipp_year0 / 1000) / 10,
      y: d.year0,
    }));

    const arr1 = filteredData.map((d) => ({
      a: d.kategori_navn,
      //v: d.utslipp_year1 / 1000,
      v: Math.round(10 * d.utslipp_year1 / 1000) / 10,
      y: d.year1,
    }));

    const arr2 = filteredData.map((d) => ({
      a: d.kategori_navn,
      //v: d.utslipp_year2 / 1000,
      v: Math.round(10 * d.utslipp_year2 / 1000) / 10,
      y: d.year2,
    }));

    const concatedData = arr1.concat(arr2).concat(arr0);

    window.innerWidth < 488
      ? concatedData.length / 3 >= 4
        ? setTiltedLabels(true)
        : setTiltedLabels(false)
      : concatedData.length / 3 > 6
      ? setTiltedLabels(true)
      : setTiltedLabels(false);

    setData(concatedData);
  }, [data, scope]);

  const activeCrossfilter = useCrossfilter(dataArr);

  useEffect(() => {
    if (!activeCrossfilter || typeof activeCrossfilter["all"] !== "function")
      return;
    const dimension = activeCrossfilter.dimension((d) => [
      d.a, //kategori_navn,
      d.y, //d.year2,
    ]);

    setDim(dimension);
    return () => dimension.dispose();
  }, [activeCrossfilter]);

  const group = useMemo(() => {
    if (!dim) return;

    return dim.group().reduceSum((d) => d.v);
  }, [dim]);

  // if (!group || group.all().length < 1) {
  //   return <div>...</div>;
  // }
  return (
    <ChartContainer
      className="barchart-container-outer"
      title={title || "Sammendrag per utslippskilde"}
    >
      <div className="scope-toggle-container">
        <SwitchToggle
          label1={
            window.innerWidth > 1050 ? "Scope 1 - Direkte utslipp" : "Scope 1"
          }
          value1={1}
          label2={
            window.innerWidth > 1050
              ? "Scope 2 - indirekte utslipp energi"
              : "Scope 2"
          }
          value2={2}
          label3={
            window.innerWidth > 1050 ? "Scope 3 - indirekte utslipp" : "Scope 3"
          }
          value3={3}
          value={scope}
          onChange={(checked) => {
            setScope(checked);
          }}
        />
      </div>
      <div
        className={[
          "barchart-inner-content",
          tiltedLabels ? "tilted" : "",
        ].join(" ")}
      >
        {dim && (
          <BarChartGrouped
            chartTitle="Grouped bar chart"
            dimension={dim}
            group={group}
            width={600}
            height={300}
            gap={window.innerWidth < 488 ? 0.05 : 0.6}
            margins={margins}
            renderLabel={true}
            renderTitle={true}
            customTitleFormat={(d) =>
              // `${d.key[0]}/${d.key[1]}: ${formatNumber(d.value)}`
              d.value
            }
            colors={d3
              .scaleOrdinal()
              .range([
                "rgb(70, 78, 163)",
                "rgb(255, 180, 153)",
                "rgb(255, 197, 102)",
              ])}
          />
        )}
      </div>
      {filteredData && filteredData.length > 0 && (
        <YearLegend
          year1={filteredData[0]["year0"]}
          year2={filteredData[0]["year1"]}
          year3={filteredData[0]["year2"]}
        />
      )}
      {filteredData && filteredData.length > 0 && (
        <TotalScopeContainer
          data={filteredData}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      )}

      <p className="key-result-footnote">
        * Oppgitt i tonn CO<sub>2</sub>-ekvivalenter
      </p>
    </ChartContainer>
  );
};
export default BarChartContainer;
