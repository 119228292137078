import React, { useMemo } from "react";
import dompurify from "dompurify";
import ChartContainer from "../components/charts/ChartContainer.js";

import "./TaksonomiContainer.scss";

const TrafficLight = ({ active = 1 }) => (
  <div className="traffic-light--container">
    {["red", "yellow", "green"].map((item, i) => (
      <span className={active === i + 1 ? "active" : ""} key={i}></span>
    ))}
  </div>
);

const TaksonomiContainer = ({ data }) => {
  const klimaendringer = useMemo(() => {
    if (!(data && data.length)) return;
    let value = data[0].tak_klimaendringer_year2;
    let description = data[0].tak_klimaendringer_bidrag_year2;

    return { value, description };
  }, [data]);

  const klimatilpasning = useMemo(() => {
    if (!(data && data.length)) return;
    let value = data[0].tak_klimatilpasning_year2;
    let description = data[0].tak_klimatilpasning_bidrag_year2;

    return { value, description };
  }, [data]);

  const ressurser = useMemo(() => {
    if (!(data && data.length)) return;
    let value = data[0].tak_ressurser_year2;
    let description = data[0].tak_ressurser_bidrag_year2;

    return { value, description };
  }, [data]);

  const sirkular = useMemo(() => {
    if (!(data && data.length)) return;
    let value = data[0].tak_sirkular_year2;
    let description = data[0].tak_sirkular_bidrag_year2;

    return { value, description };
  }, [data]);

  const forurensning = useMemo(() => {
    if (!(data && data.length)) return;

    let value = data[0].tak_forurensning_year2;
    let description = data[0].tak_forurensning_bidrag_year2;
    return { value, description };
  }, [data]);

  const okosystemer = useMemo(() => {
    if (!(data && data.length)) return;

    let value = data[0].tak_okosystemer_year2;
    let description = data[0].tak_okosystemer_bidrag_year2;
    return { value, description };
  }, [data]);
  
  const hasData = useMemo(() => {
    let display = false;
    if (!(data && data.length)) return display;
    
    display = data[0].show_eu_taxonomy_year2;

    return display;
  }, [data]);

  return (
    <>
      {hasData && (
        <ChartContainer
          title="EUs taksonomi for bærekraftig finans"
          className="taksonomi-container"
        >
          <p>
            EUs taksonomi er et verktøy og et klassifikasjonssystem for bærekraftige
            aktiviteter som skal hjelpe investorer, virksomheter, utstedere og andre
            i omstillingen til en lavkarbon-, motstandsdyktig- og ressurseffektiv
            økonomi på veien mot lavutslippssamfunnet. Prinsippet er ganske enkelt
            at man må vise at man «bidrar substansielt» til minst ett av de seks
            parameterne, men at man samtidig «ikke gjør betydelig skade» på noen av
            de andre parameterne. Dette er en vurdering som gjøres på
            aktivitetsnivå, men i rapporteringen nedenfor vises en samlet vurdering.
          </p>
          <div className="taksonomi-content">
            <div>
              <h3>2.1 Forhindre klimaendringer</h3>
              <TrafficLight active={klimaendringer.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(klimaendringer.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
            <div>
              <h3>2.2 Klimatilpasning</h3>
              <TrafficLight active={klimatilpasning.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(klimatilpasning.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
            <div>
              <h3>
                2.3 Bærekraftig bruk og beskyttelse av vann og marine ressurser
              </h3>
              <TrafficLight active={ressurser.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(ressurser.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
            <div>
              <h3>2.4 Omstilling til en sirkulær økonomi</h3>
              <TrafficLight active={sirkular.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(sirkular.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
            <div>
              <h3>2.5 Forhindre og kontrollere forurensning</h3>
              <TrafficLight active={forurensning.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(forurensning.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
            <div>
              <h3>2.6 Beskytte sunne økosystemer</h3>
              <TrafficLight active={okosystemer.value} />
              <p
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(okosystemer.description, { ADD_ATTR: ['target'] }),
                }}
              />
            </div>
          </div>
        </ChartContainer>
      )}
    </>
  )
};

export default TaksonomiContainer;
