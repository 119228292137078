import React, { useMemo } from "react";
import { formatReturnValue } from "../utils/number";
import "../components/charts/BarChartContainer.scss";

const TotalScopeContainer = ({ data, pdfTitle, toggleCheckedFrivillig }) => {
  const totalScopePurple = useMemo(() => {
    if (!(data && data.length > 0)) return;

    let value = toggleCheckedFrivillig
      ? data[0].total_scope_year0
      : data[0].total_scope_uf_year0;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);

  const totalScopePurpleTitle = data && data.length > 0 ? data[0].year0 : "";

  const totalScopePink = useMemo(() => {
    if (!(data && data.length > 0)) return;
    let value = toggleCheckedFrivillig
      ? data[0].total_scope_year1
      : data[0].total_scope_uf_year1;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);
  const totalScopePinkTitle = data && data.length > 0 ? data[0].year1 : "";

  const totalScopeYellow = useMemo(() => {
    if (!(data && data.length > 0)) return;

    let value = toggleCheckedFrivillig
      ? data[0].total_scope_year2
      : data[0].total_scope_uf_year2;

    return value === 0 ? value : value / 1000;
  }, [data, toggleCheckedFrivillig]);
  const totalScopeYellowTitle = data && data.length > 0 ? data[0].year2 : "";

  return (
    <div className="klimagassutslipp-content">
      <p className="chart-title">
        {pdfTitle ? pdfTitle : "Sum utslipp per valgt scope"}
      </p>
      <div className="data-circle-container">
        <div className="data-circle-inner">
          <div className="data-circle purple">
            {data && data.length !== 0 && totalScopePurple !== 0
              ? formatReturnValue(totalScopePurple)
              : "-"}
          </div>
          <p>{totalScopePurpleTitle}</p>
        </div>
        <div className="data-circle-inner">
          <div className="data-circle pink">
            {data && data.length !== 0 && totalScopePink !== 0
              ? formatReturnValue(totalScopePink)
              : "-"}
          </div>
          <p>{totalScopePinkTitle}</p>
        </div>
        <div className="data-circle-inner">
          <div className="data-circle yellow">
            {data && data.length !== 0 && totalScopeYellow !== 0
              ? formatReturnValue(totalScopeYellow)
              : "-"}
          </div>
          <p>{totalScopeYellowTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default TotalScopeContainer;
