import React, { useMemo } from "react";
import { HoverableTooltip } from "../components/ClimateVisionText.js";
import ChartContainer from "../components/charts/ChartContainer.js";
import capro12Img from "../static/key_business_icon/CAPRO_12.png";
import capro123Img from "../static/key_business_icon/CAPRO_123.png";
import capexImg from "../static/key_business_icon/CAPEX.png";
import { formatReturnValue } from "../utils/number.js";
import "./KeyBusinessContainer.scss";

const KeyBusinessContainerGreen = ({ data }) => {
  const capro12Data = useMemo(() => {
    if (!(data && data.length)) return;

    let value = data[0]?.capro12_year2;

    return value;
  }, [data]);

  const capro123Data = useMemo(() => {
    if (!(data && data.length)) return;

    let value = data[0]?.capro123_year2;

    return value;
  }, [data]);

  const capexData = useMemo(() => {
    if (!(data && data.length)) return;

    let description = data[0]?.tak_investeringer_bidrag_year2;
    let value = data[0]?.tak_investeringer_nok_year2;

    return { value, description };
  }, [data]);

  return (
    <ChartContainer title="Grønne verdiskapningsindikatorer">
      <div className="key-business-result-content">
        <div className="circle-container">
          <div className="circle-text-img-content">
            <div className="key-business-icon-title">
              Capro{" "}
              <HoverableTooltip tooltipText="Karbonproduktivitet (CAPRO) er en indikator for grønn vekst, og er forholdet mellom verdiskapning i kroner og virksomhetens utslipp. Tallene (eks. 1+2) angir scope for utslipp. Mer informasjon om CAPRO er oppgitt i FAQ" />
            </div>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {capro12Data && capro12Data !== 0
                  ? formatReturnValue(capro12Data)
                  : "-"}
              </p>
              <img src={capro12Img} alt="omsetning-img" className="kbc-icon" />
            </div>
          </div>

          <div className="circle-text-img-content">
            <div className="key-business-icon-title">
              Capro{" "}
              <HoverableTooltip tooltipText="Karbonproduktivitet (CAPRO) er en indikator for grønn vekst, og er forholdet mellom verdiskapning i kroner og virksomhetens utslipp. Tallene (eks. 1+2+3) angir scope for utslipp. Mer informasjon om CAPRO er oppgitt i FAQ" />
            </div>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {capro123Data && capro123Data !== 0
                  ? formatReturnValue(capro123Data)
                  : "-"}
              </p>
              <img src={capro123Img} alt="omsetning-img" className="kbc-icon" />
            </div>
          </div>

          <div className="circle-text-img-content">
            <div className="key-business-icon-title asterisk">
              Capex <sup>*</sup>{" "}
              <HoverableTooltip
                tooltipText="CAPEX oppgir antall investerte kroner i tiltak for å bedre virksomhetens miljøparametere etter EUs taksonomi for bærekraftig finans"
              />
            </div>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {capexData.value && capexData.value !== 0
                  ? formatReturnValue(capexData.value)
                  : "-"}
              </p>
              <img src={capexImg} alt="omsetning-img" className="kbc-icon" />
            </div>
          </div>
        </div>
      </div>
      <p className="key-result-footnote">
        * Investeringer for å bidra til miljøparametere etter EUs taksonomi
      </p>
    </ChartContainer>
  );
};

export default KeyBusinessContainerGreen;
