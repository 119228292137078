import React, { useState } from "react";
import Icon from "../Icon.js";

import "./ChartContainer.scss";

const ChartContainer = ({
  title,
  className,
  children,
  chartContainerCollapsed = false,
}) => {
  const [collapsed, setCollapsed] = useState(chartContainerCollapsed);

  return (
    <div
      className={["chart-content-container", className ? className : ""].join(
        " "
      )}
    >
      <div className={"chart-top-menu" + (title ? "" : " none")}>
        <p className={"chart-title"}>{title}</p>
        <button
          className="closing-button"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? (
            <Icon name="triangle_down" />
          ) : (
            <Icon name="triangle_up" />
          )}
        </button>
      </div>
      <div
        className={["chart-inner-content", collapsed ? "closed" : "open"].join(
          " "
        )}
      >
        <div
          className={["chart-inner-container", className ? className : ""].join(
            " "
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChartContainer;
