import React from "react";
import dompurify from "dompurify";
import PDFexport from "./PDFexport";
import kp_logo from "../../static/kp_logo_purple.svg";
import config from "../../config/index";
import ScopeBoxes from "../../components/ScopeBoxes.js";
import "./PDFexporter.scss";

export const CoverPage = ({ title, subTitle, year }) => (
  <div className="cover-page-container">
    <img src={kp_logo} className="kp-logo-img" alt="kp-logo" />
    <div className="cover-title-container">
      <p
        className={
          title && title.length > 17 ? "main-title large" : "main-title"
        }
      >
        {title}
      </p>
      <hr className="cover-underline" />
      <p className="pdf-sub-title">{subTitle}</p>
      <p className="pdf-year-title">{"Klimaregnskap " + (year && year)}</p>
    </div>
    <div className="cover-page-img" />
  </div>
);

export const IntroductionPage = ({ title, textContent, large }) => (
  <div className="introduction-page-container">
    <div
      className={["introduction-title-container", large ? " large" : ""].join(
        " "
      )}
    >
      <p className="introduction-main-title">{title}</p>
      <hr className="cover-underline" />
      <p
        className={"introduction-text-content"}
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(textContent),
        }}
      />
    </div>
    <div className="introduction-page-img" />
  </div>
);

export const MethodPage = () => (
  <div className="method-page-container">
    <h2
      className={"method-title"}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(
          config.klimaregnskapText["metodeFirstDescription"]
        ),
      }}
    />
    <p
      className={"method-text-content"}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(config.klimaregnskapText["metodeFirstText"]),
      }}
    />

    <p
      className={"method-subTitle"}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(
          config.klimaregnskapText["metodeFirstDescription"]
        ),
      }}
    />

    <p
      className={"method-text-content"}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(
          config.klimaregnskapText["metodeSecondText"]
        ),
      }}
    />

    <div className="scope-box-container">
      {config.scopeContent.map((content, idx) => {
        return (
          <ScopeBoxes
            key={idx}
            img={content.img}
            title={content.title}
            text={content.description}
          />
        );
      })}
    </div>
  </div>
);

class PDFexporter extends React.Component {
  render() {
    const {
      titlePage,
      PDFType,
      groupId,
      elTypePDF,
      orgnumber,
      setIsReady,
      data,
      totalData,
      budgetData,
      textContent,
      toggleCheckedFrivillig,
      currentYear,
    } = this.props;

    return (
      <PDFexport
        titlePage={titlePage}
        groupId={groupId}
        elTypePDF={elTypePDF}
        orgnumber={orgnumber}
        PDFType={PDFType}
        setIsReady={setIsReady}
        data={data}
        totalData={totalData}
        budgetData={budgetData}
        textContent={textContent}
        toggleCheckedFrivillig={toggleCheckedFrivillig}
        currentYear={currentYear}
      />
    );
  }
}
export default PDFexporter;
