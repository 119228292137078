import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FAQ from "../components/FAQ";
import { MainLayout } from "../layouts/MainLayout";
import FrontPage from "../views/FrontPage";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfclicked: true,
    };
  }
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL + "/"}>
        <MainLayout pdfclicked={this.state.pdfclicked}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/0/nasjonal" />
            </Route>
            {/* <Route exact path="/9/faq" component={FAQ} /> */}
            <Route exact path="/10/faq" component={FAQ} />
            <Route path="/*" component={FrontPage} />
          </Switch>
        </MainLayout>
      </Router>
    );
  }
}
export default AppRouter;
