import React, { useEffect, useMemo, useState, useRef } from "react";
import dc from "dc";
import * as d3 from "d3";
import Page from "./Page.js";
import ClimateVisionText from "../ClimateVisionText";
import ClimateVisionIconBox from "../ClimateVisionIconBox";
import KeyResultContainer from "../../containers/KeyResultContainer";
import KeyBusinessContainer from "../../containers/KeyBusinessContainer";
import TotalSumContainer from "../TotalSumContainer";
import BarChartContainer from "../charts/BarChartContainer";
import ChartContainer from "../charts/ChartContainer";
import TimeBarChartContainer from "../../components/charts/TimeBarChartContainer";
import BudgetChartContainer from "../charts/BudgetChartContainer.js";
import TotalScopeContainer from "../TotalScopeContainer.js";
import useCrossfilter from "../../hooks/useCrossfilter";
import { PieChart } from "avinet-dashboard-lib";
import { IntroductionPage } from "./PDFexporter";
import { createTextBox } from "../../views/FrontPage";
import "./PDFexportSingle.scss";
import KeyBusinessContainerGreen from "../../containers/KeyBusinessContainerGreen.js";
import TaksonomiContainer from "../../containers/TaksonomiContainer.js";

const PDFexportSingleBuisimness = ({
  description,
  toggleCheckedFrivillig,
  data,
  budgetData,
  textContent,
  orgnumber,
  cf,
}) => {
  const [pieChartDim, setPieChartDim] = useState();

  const createMaalutdyping = createTextBox(
    orgnumber,
    textContent,
    "maalutdyping"
  );

  const createKommentar = createTextBox(orgnumber, textContent, "kommentar");

  const createMiljosertifisering = createTextBox(
    orgnumber,
    textContent,
    "miljosertifisering"
  );

  const createKlimavisjon = createTextBox(
    orgnumber,
    textContent,
    "klimavisjon"
  );

  const createSpydspiss = createTextBox(
    orgnumber,
    textContent,
    "spydspissprosjekter"
  );

  const createBestePraksis = createTextBox(
    orgnumber,
    textContent,
    "bestepraksis"
  );

  const createKlimatiltak = createTextBox(
    orgnumber,
    textContent,
    "klimatiltak"
  );

  const createTjenesterProdukter = createTextBox(
    orgnumber,
    textContent,
    "tjenesterprodukter"
  );

  const createKommunikasjon = createTextBox(
    orgnumber,
    textContent,
    "kommunikasjon"
  );

  const chartRef = useRef();

  // PieChart
  useEffect(() => {
    if (!cf) return;

    const dimension = cf.dimension((d) => d.kategori_navn);
    setPieChartDim(dimension);

    return () => dimension.dispose();
  }, [cf]);

  const pieChartGroup = useMemo(() => {
    if (!pieChartDim) return;

    return pieChartDim
      .group()

      .reduceSum((d) => d.utslipp_year2 / 1000);
  }, [pieChartDim]);

  const dataSorted = useMemo(() => {
    if (!data) return;

    const arr0 = data.map((d, idx) => {
      return {
        idx,
        year: d.year0,
        type: d.kategori_navn,
        value: d.utslipp_year0,
      };
    });

    const arr1 = data.map((d, idx) => {
      return {
        idx,
        year: d.year1,
        type: d.kategori_navn,
        value: d.utslipp_year1,
      };
    });

    const arr2 = data.map((d, idx) => {
      return {
        idx,
        year: d.year2,
        type: d.kategori_navn,
        value: d.utslipp_year2,
      };
    });

    return arr0.concat(arr1).concat(arr2);
  }, [data]);

  const activeCrossfilter = useCrossfilter(dataSorted);
  const budgetCrossfilter = useCrossfilter(budgetData);

  const carbonNeutral = textContent ? textContent.klimakvoter : null;

  const nullUtslippScope1 = orgnumber
    ? data && data[0].antall_scope_0_year2 === 1
      ? true
      : false
    : undefined;

  return (
    <div className="pdf-export-single-business">
      <Page className="margin-free">
        <IntroductionPage
          title={"Om virksomheten"}
          textContent={description}
          large={description && description.length > 2500}
        />
      </Page>

      {createKlimavisjon &&
      textContent &&
      textContent["klimavisjon"] &&
      textContent["klimavisjon"].length > 500 ? (
        <>
          <Page>
            {(nullUtslippScope1 || carbonNeutral) && (
              <ClimateVisionText
                className="about"
                totalCompaniesInRegion={
                  data && data.length !== 0
                    ? data[0].antall_bedrifter_year2
                    : ""
                }
                totalCompaniesScope0={
                  data && data.length !== 0
                    ? toggleCheckedFrivillig
                      ? data[0].antall_scope_0_year2
                      : data[0].antall_scope_0_uf_year2
                    : ""
                }
                orgnumber={orgnumber}
                rowName={["omvirksomheten"]}
                fossilFree={nullUtslippScope1}
                carbonNeutralQuotas={carbonNeutral || 0}
              />
            )}

            {createKlimavisjon && (
              <ClimateVisionText
                textContent={textContent}
                title={["Klimavisjon"]}
                rowName={["klimavisjon"]}
              />
            )}
          </Page>
          <Page>
            <ClimateVisionIconBox
              title={"Klimamål"}
              text={
                "Grønne symbol betyr at virksomheten har satt seg gjeldene mål, har etablert gjeldende plan eller oppnådd miljøsertifisering. Oransje symbol betyr at virksomheten ikke har det."
              }
              textContent={textContent}
            />
          </Page>
        </>
      ) : (
        <Page>
          {(nullUtslippScope1 || carbonNeutral) && (
            <ClimateVisionText
              className="about"
              totalCompaniesInRegion={
                data && data.length !== 0 ? data[0].antall_bedrifter_year2 : ""
              }
              totalCompaniesScope0={
                data && data.length !== 0
                  ? toggleCheckedFrivillig
                    ? data[0].antall_scope_0_year2
                    : data[0].antall_scope_0_uf_year2
                  : ""
              }
              orgnumber={orgnumber}
              rowName={["omvirksomheten"]}
              fossilFree={nullUtslippScope1}
              carbonNeutralQuotas={carbonNeutral ? carbonNeutral : 0}
            />
          )}

          {createKlimavisjon && (
            <ClimateVisionText
              textContent={textContent}
              title={["Klimavisjon"]}
              rowName={["klimavisjon"]}
            />
          )}
          <ClimateVisionIconBox
            title={"Klimamål"}
            text={
              "Grønne symbol betyr at virksomheten har satt seg gjeldene mål, har etablert gjeldende plan eller oppnådd miljøsertifisering. Oransje symbol betyr at virksomheten ikke har det."
            }
            textContent={textContent}
          />
        </Page>
      )}

      {(createMaalutdyping &&
        textContent["maalutdyping"] &&
        textContent["maalutdyping"].length) +
        (createKommentar &&
          textContent["kommentar"] &&
          textContent["kommentar"].length) >
      1000 ? (
        <>
          <Page>
            {createMaalutdyping && (
              <ClimateVisionText
                textContent={textContent}
                title={["Utdyping klimamål"]}
                rowName={["maalutdyping"]}
              />
            )}
          </Page>
          <Page>
            {createKommentar && (
              <ClimateVisionText
                textContent={textContent}
                title={["Kommentar til utslippsdata"]}
                rowName={["kommentar"]}
              />
            )}
            {data && data.length !== 0 && (
              <KeyResultContainer
                data={data}
                compareToEarlierYear={true}
                pdfTitle={false}
                toggleCheckedFrivillig={toggleCheckedFrivillig}
              />
            )}
          </Page>
        </>
      ) : (
        <Page>
          {createMaalutdyping && (
            <ClimateVisionText
              textContent={textContent}
              title={["Utdyping klimamål"]}
              rowName={["maalutdyping"]}
            />
          )}
          {createKommentar && (
            <ClimateVisionText
              textContent={textContent}
              title={["Kommentar til utslippsdata"]}
              rowName={["kommentar"]}
            />
          )}
          {data && data.length !== 0 && (
            <KeyResultContainer
              data={data}
              compareToEarlierYear={true}
              pdfTitle={false}
              toggleCheckedFrivillig={toggleCheckedFrivillig}
            />
          )}
        </Page>
      )}

      <Page>
        <BarChartContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
          propScope={1}
          title="Scope 1 - Direkte utslipp"
          className="barchart-container-scope-1"
          margins={[10, 40, 70, 35]}
        />
        <BarChartContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
          propScope={2}
          title="Scope 2 - Indirekte utslipp energi"
          margins={[10, 40, 70, 35]}
        />
        <BarChartContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
          propScope={3}
          title="Scope 3 - Indirekte utslipp"
          margins={[10, 40, 130, 35]}
        />
      </Page>

      <Page>
        <ChartContainer>
          <TotalScopeContainer
            data={data.filter((v) => v.scope === 1)}
            toggleCheckedFrivillig={toggleCheckedFrivillig}
            pdfTitle="Scope 1 - Direkte utslipp"
          />
        </ChartContainer>

        <ChartContainer>
          <TotalScopeContainer
            data={data.filter((v) => v.scope === 2)}
            toggleCheckedFrivillig={toggleCheckedFrivillig}
            pdfTitle="Scope 2 - Indirekte utslipp energi"
          />
        </ChartContainer>

        <ChartContainer>
          <TotalScopeContainer
            data={data.filter((v) => v.scope === 3)}
            toggleCheckedFrivillig={toggleCheckedFrivillig}
            pdfTitle="Scope 3 - Indirekte utslipp"
          />
        </ChartContainer>
      </Page>

      <Page>
        <TotalSumContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
      </Page>

      <Page>
        <KeyBusinessContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
        />
        <KeyBusinessContainerGreen data={data} />
      </Page>

      <Page>
        <TaksonomiContainer data={data} />
      </Page>

      <Page>
        <div className="pdf-chart-container-content">
          <ChartContainer title="Samlet klimaregnskap" className="">
            {pieChartDim && (
              <div className="pdf-pie-chart-div">
                <PieChart
                  ref={chartRef}
                  className="piechart"
                  group={pieChartGroup}
                  dimension={pieChartDim}
                  circularLegend
                  minAngleForLabel={0.5}
                  externalLabels={-40}
                  slicesCap={25}
                  height={350}
                  radius={350}
                  showLegend
                  margins={[10, 0, 50, 0]}
                  colors={d3
                    .scaleOrdinal()
                    .range([
                      "#c6dbef",
                      "#c7e9c0",
                      "#e6550d",
                      "#6baed6",
                      "#fdae6b",
                      "#9e9ac8",
                      "#31a354",
                      "#bcbddc",
                      "#756bb1",
                      "#dadaeb",
                      "#a1d99b",
                      "#003e74",
                      "#fdd0a2",
                      "#74c476",
                      "#9ecae1",
                      "#fd8d3c",
                    ])
                    .domain([
                      "Andre reiser (tog/buss/taxi)",
                      "Avfall til gjenvinning",
                      "Bygg (frivillig)",
                      "Drivstoff (kjøretøy og annet forbruk)",
                      "Elektrisitet",
                      "Fjernvarme/-kjøling",
                      "Flyreiser",
                      "Fyringsolje",
                      "Km-godtgjørelse",
                      "Pendling",
                      "Propan/natur-/lystgass",
                      "Prosessutslipp",
                      "Reise og transport (frivillig)",
                      "Restavfall til forbrenning",
                      "Tjenester (frivillig)",
                      "Varer (frivillig)",
                    ])}
                  label={() =>
                    chartRef.current &&
                    chartRef.current.chart.on("pretransition", (chart) => {
                      chart
                        .selectAll("text.pie-slice")
                        .text((d) =>
                          dc.utils.printSingleValue(
                            ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100
                          ) < 10
                            ? ""
                            : dc.utils.printSingleValue(
                                ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
                                  100
                              ) + "%"
                        );
                    })
                  }
                />
                {/* hacky way to get ref working, will need to improve this */}
                <div style={{ height: 0, overflow: "hidden" }}>
                  <PieChart
                    ref={chartRef}
                    className="piechart"
                    group={pieChartGroup}
                    dimension={pieChartDim}
                    height={350}
                    radius={350}
                    colors={d3
                      .scaleOrdinal()
                      .range([
                        "#c6dbef",
                        "#c7e9c0",
                        "#e6550d",
                        "#6baed6",
                        "#fdae6b",
                        "#9e9ac8",
                        "#31a354",
                        "#bcbddc",
                        "#756bb1",
                        "#dadaeb",
                        "#a1d99b",
                        "#003e74",
                        "#fdd0a2",
                        "#74c476",
                        "#9ecae1",
                        "#fd8d3c",
                      ])
                      .domain([
                        "Andre reiser (tog/buss/taxi)",
                        "Avfall til gjenvinning",
                        "Bygg (frivillig)",
                        "Drivstoff (kjøretøy og annet forbruk)",
                        "Elektrisitet",
                        "Fjernvarme/-kjøling",
                        "Flyreiser",
                        "Fyringsolje",
                        "Km-godtgjørelse",
                        "Pendling",
                        "Propan/natur-/lystgass",
                        "Prosessutslipp",
                        "Reise og transport (frivillig)",
                        "Restavfall til forbrenning",
                        "Tjenester (frivillig)",
                        "Varer (frivillig)",
                      ])}
                    label={() =>
                      chartRef.current &&
                      chartRef.current.chart.on("renderlet", (chart) => {
                        chart
                          .selectAll("text.pie-slice")
                          .text((d) =>
                            dc.utils.printSingleValue(
                              ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
                                100
                            ) < 10
                              ? ""
                              : dc.utils.printSingleValue(
                                  ((d.endAngle - d.startAngle) /
                                    (2 * Math.PI)) *
                                    100
                                ) + "%"
                          );
                      })
                    }
                  />
                </div>
              </div>
            )}
          </ChartContainer>
        </div>
        <ClimateVisionIconBox
          textContent={textContent}
          title={"Antall fossile og fossilfri kjøretøy"}
        />
      </Page>

      {(createSpydspiss || createBestePraksis || createKlimatiltak) && (
        <Page>
          {createSpydspiss && (
            <ClimateVisionText
              textContent={textContent}
              title={["Spydspissprosjekter "]}
              rowName={["spydspissprosjekter"]}
            />
          )}
          {createBestePraksis && (
            <ClimateVisionText
              textContent={textContent}
              title={["Beste praksis "]}
              rowName={["bestepraksis"]}
            />
          )}
          {createKlimatiltak && (
            <ClimateVisionText
              textContent={textContent}
              title={["Gjennomførte klimatiltak "]}
              rowName={["klimatiltak"]}
            />
          )}
        </Page>
      )}

      {(createTjenesterProdukter ||
        createKommunikasjon ||
        createMiljosertifisering) && (
        <Page>
          {createTjenesterProdukter && (
            <ClimateVisionText
              textContent={textContent}
              title={["Tjenester og produkter"]}
              rowName={["tjenesterprodukter"]}
            />
          )}
          {createKommunikasjon && (
            <ClimateVisionText
              textContent={textContent}
              title={["Kommunikasjon av klimaarbeid"]}
              rowName={["kommunikasjon"]}
            />
          )}
          {createMiljosertifisering && (
            <ClimateVisionText
              textContent={textContent}
              title={["Miljøsertifisering"]}
              rowName={["miljosertifisering"]}
            />
          )}
        </Page>
      )}

      <Page>
        <TimeBarChartContainer
          activeCrossfilter={activeCrossfilter}
          isNational={false}
          isPdf
        />
      </Page>

      {budgetData && budgetData.length > 0 && (
        <Page>
          <BudgetChartContainer
            activeCrossfilter={budgetCrossfilter}
            isNational={false}
            isPdf
          />
        </Page>
      )}
    </div>
  );
};
export default PDFexportSingleBuisimness;
