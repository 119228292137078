import React from "react";
import PropTypes from "prop-types";
import "./SwitchToggle.scss";

const SwitchToggle = ({
  label1,
  label2,
  label3,
  label4,
  title1,
  title2,
  value1,
  value2,
  value3,
  value4,
  value,
  onChange,
  className,
}) => {
  return (
    <div className={"toggle-container " + className}>
      <label
        className={["btn small", value === value1 ? "active" : ""].join(" ")}
      >
        <input
          type="radio"
          checked={value === value1}
          onChange={() => onChange(value1)}
        />
        {label1}
        {title1 && <span className="tooltip">{title1}</span>}
      </label>

      <span className="mobile" />

      <label
        className={["btn small", value === value2 ? "active" : ""].join(" ")}
      >
        <input
          type="radio"
          checked={value === value2}
          onChange={() => onChange(value2)}
        />
        {label2}
        {title2 && <span className="tooltip">{title2}</span>}
      </label>

      {value3 && <span className="mobile" />}

      {value3 && (
        <label
          className={["btn small", value === value3 ? "active" : ""].join(" ")}
        >
          <input
            type="radio"
            checked={value === value3}
            onChange={() => onChange(value3)}
          />
          {label3}
        </label>
      )}

      {value4 && <span className="mobile" />}

      {value4 && (
        <label
          className={["btn small", value === value4 ? "active" : ""].join(" ")}
        >
          <input
            type="radio"
            checked={value === value4}
            onChange={() => onChange(value4)}
          />
          {label4}
        </label>
      )}
    </div>
  );
};

SwitchToggle.propTypes = {
  /** 1. Label */
  label1: PropTypes.string.isRequired,
  /** 1. Value */
  value1: PropTypes.any,
  /** 2. Label */
  label2: PropTypes.string.isRequired,
  /** 2. Value */
  value2: PropTypes.any,
  /** 3. Label */
  label3: PropTypes.string,
  /** 3. Value */
  value3: PropTypes.any,
  /** Current value of switch */
  value: PropTypes.any.isRequired,
  /** Use callback to set state in parent compoenent */
  onChange: PropTypes.func.isRequired,
};

export default SwitchToggle;
