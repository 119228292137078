import { useMemo, useEffect } from "react";
import dc from "dc";
import crossfilter from "crossfilter2";

export default function useCrossfilter(data, chartGroup = undefined) {
  const _crossfilter = useMemo(() => crossfilter(), []);
  useEffect(() => {
    if (data) {
      _crossfilter.remove(() => true);
      _crossfilter.add(data);
      dc.redrawAll(chartGroup);
    }
  }, [_crossfilter, data, chartGroup]);
  return _crossfilter;
}
