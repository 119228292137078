import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";

export default function useText(
  isNational,
  groupid,
  orgnumber = null,
  skip_new_members = false,
  filter = false,
  scope = null,
  eltype = 2,
  getText = true,
  toyear = 2021,
  includeFrivillig = false, // maybe true?
  onlyFrivillig = false
) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (groupid === "-") {
      return;
    }
    setData(null);
    setLoading(true);

    const req = orgnumber
      ? request
        // Vær obs
        .get(`${config.apiUrl}/getCompanyData`)
        .query({ orgnumber: orgnumber })
        .query({ toyear: toyear })
        .query({ eltype: eltype })
        .query({ filter: filter })
      : isNational
      ? request
        .get(`${config.apiUrl}/getPartnersDataMulti`)
        .query({ skip_new_members: skip_new_members })
        .query({ groupid: [1, 2, 3, 4, 5, 6, 7, 8] })
        .query({ toyear: toyear })
        .query({ eltype: eltype })
        .query({ filter: filter })
      : request
        .get(`${config.apiUrl}/getPartnersData`)
        .query({ skip_new_members: skip_new_members })
        .query({ groupid: groupid })
        .query({ toyear: toyear })
        .query({ eltype: eltype })
        //.query({ scope: scope }) //??
        .query({ filter: filter });

    req
      .then((res) =>
        orgnumber ? res.body.data && res.body.data.companyinfo : res.body.data
      )
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [
    eltype,
    toyear,
    groupid,
    scope,
    orgnumber,
    getText,
    includeFrivillig,
    onlyFrivillig,
    skip_new_members,
    isNational,
    filter,
  ]);

  return [data, loading];
}
