import React, { useState } from "react";
import dompurify from 'dompurify';
import config from "../config/index.js";
import ScopeBoxes from "../components/ScopeBoxes.js";
import ChartContainer from "../components/charts/ChartContainer.js";
import "./AboutPage.scss";

const PDFmenuDisplay = () => {
  return (
    <div className="pdf-display-box">
      {Object.keys(config.pfdRegion).map((area, idx) => {
        return (
          <div key={idx}>
            <p className="about-KP-description">
              {config.pfdRegion[area].pdf.length > 0 &&
                config.pfdRegion[area].title}
            </p>

            {config.pfdRegion[area].pdf.length !== 0 &&
              config.pfdRegion[area].pdf.map((pdf) => {
                return (
                  <a
                    key={pdf}
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {pdf.split("/")[pdf.split("/").length - 1]}
                  </a>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

const AboutPage = ({ titlePage }) => {
  const [displayPDF, setDisplayPDF] = useState(false);
  return (
    <div className="about-KP-page-container">
      <div className="about-KP-top-box">
        {!titlePage && <p className="kp-title">klimaregnskap</p>}
        <div
          className="about-KP-description"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.KPDescription),
          }}
        />
        <div
          className="about-KP-text"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.KPText),
          }}
        />
        <ChartContainer
          title={config.deviationText.title}
          chartContainerCollapsed={true}
        >
          <p
            className={"about-KP-text"}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(config.deviationText.description),
            }}
          />
        </ChartContainer>
        <div className="pdf-text-and-icon-container">
          <p
            className="display-pdf-text"
            onClick={() => setDisplayPDF(!displayPDF)}
          >
            For å se tidligere Klimaregnskapsrapporter, klikk her.
          </p>
          {/**displayPDF ? (
            <Icon name="triangle_up" />
          ) : (
            <Icon name="triangle_down" />
          )**/}
        </div>{" "}
        {displayPDF ? <PDFmenuDisplay /> : ""}
      </div>
      <div className="about-KP-second-box">
        <p className="kp-title">Metode</p>
        <div
          className="about-KP-description"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.metodeFirstDescription),
          }}
        />
        <div
          className="about-KP-text"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.metodeFirstText),
          }}
        />
        <div
          className="about-KP-description"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.metodeSecondDescription),
          }}
        />
        <div
          className="about-KP-text"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(config.klimaregnskapText.metodeSecondText),
          }}
        />
      </div>
      <div className="scope-box-container">
        {config.scopeContent.map((content, idx) => {
          return (
            <ScopeBoxes
              key={idx}
              img={content.img}
              title={content.title}
              text={content.description}
            />
          );
        })}
      </div>
      <div
        className="about-KP-description"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(config.klimaregnskapText.metodeThirdDescription),
        }}
      />
      <div
        className="about-KP-text"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(config.klimaregnskapText.metodeThirdText),
        }}
      />

      <div className="calculation-scope">
        <img src={config.utregningContent[0].img} alt="" />
        <div>
          <div className="calculation-title-description-box">
            <h5 className="calculation-scope-title">
              {config.utregningContent[0].title}
            </h5>
            <p
              className={"calculation-scope-description"}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(config.utregningContent[0].description),
              }}
            />
          </div>

          <div className="calculation-title-description-box">
            <h5 className="calculation-scope-title">
              {config.utregningContent[0].title2}
            </h5>
            <p
              className={"calculation-scope-description"}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(config.utregningContent[0].description2),
              }}
            />
          </div>
        </div>
      </div>

      <div className="calculation-scope">
        <img src={config.utregningContent[1].img} alt="" />
        <div>
          <div className="calculation-title-description-box">
            <h5 className="calculation-scope-title">
              {config.utregningContent[1].title}
            </h5>
            <p
              className={"calculation-scope-description"}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(config.utregningContent[1].description),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
