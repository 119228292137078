import React, { useMemo } from "react";
import ChartContainer from "../components/charts/ChartContainer.js";
import turnoverImg from "../static/key_business_icon/turnover.png";
import employeeImg from "../static/key_business_icon/ansatt.png";
import officeImg from "../static/key_business_icon/kwh-areal.png";

import { formatReturnValue } from "../utils/number.js";
import "./KeyBusinessContainer.scss";

const KeyBusinessContainer = ({ data, toggleCheckedFrivillig }) => {
  const turnoverData = useMemo(() => {
    if (!(data && data.length)) return;

    let perValue = toggleCheckedFrivillig
      ? data[0]?.utslipppromsetning_year2
      : data[0]?.utslipppromsetning_uf_year2;

    let totalValue = data[0]?.omsetning_year2;

    return { perValue, totalValue };
  }, [data, toggleCheckedFrivillig]);

  const employeeData = useMemo(() => {
    if (!(data && data.length)) return;

    let perValue = toggleCheckedFrivillig
      ? data[0]?.sumutslippansatt_year2
      : data[0]?.sumutslippansatt_uf_year2;

    let totalValue = data[0]?.ansatte_year2;

    return { perValue, totalValue };
  }, [data, toggleCheckedFrivillig]);

  const perAreaData = useMemo(() => {
    if (!(data && data.length)) return;

    let perValue = data[0]?.utslippareal_year2;

    let totalValue = data[0]?.oppvarmet_areal_year2;

    return { perValue, totalValue };
  }, [data]);

  return (
    <ChartContainer title="Nøkkelindikatorer">
      <div className="key-business-result-content">
        <div className="circle-container">
          <div className="circle-text-img-content">
            <p className="key-business-icon-title">
              CO<sub>2</sub> per omsetning <sup>*</sup>
            </p>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {turnoverData.perValue && turnoverData.perValue !== 0
                  ? formatReturnValue(turnoverData.perValue)
                  : "-"}
              </p>
              <img src={turnoverImg} alt="omsetning-img" className="kbc-icon" />
            </div>
            <div className="total-employees">
              <div>Omsetning:</div>
              <div>
                {turnoverData.totalValue && turnoverData.totalValue !== 0
                  ? `${formatReturnValue(
                      turnoverData.totalValue / 1000000
                    )} MNOK`
                  : "-"}
              </div>
            </div>
          </div>

          <div className="circle-text-img-content">
            <p className="key-business-icon-title">
              CO<sub>2</sub> per årsverk<sup>*</sup>
            </p>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {employeeData.perValue && employeeData.perValue !== 0
                  ? formatReturnValue(employeeData.perValue)
                  : "-"}
              </p>
              <img src={employeeImg} alt="ansatt-img" className="kbc-icon" />
            </div>
            <div className="total-employees">
              <div>Årsverk:</div>
              <div>
                {employeeData.totalValue && employeeData.totalValue !== 0
                  ? formatReturnValue(employeeData.totalValue)
                  : "-"}
              </div>
            </div>
          </div>

          <div className="circle-text-img-content">
            <p className="key-business-icon-title">kWh energibruk per areal</p>
            <div className="circle-pink-bg">
              <p className="circle-key-number">
                {perAreaData.perValue && perAreaData.perValue !== 0
                  ? formatReturnValue(perAreaData.perValue)
                  : "-"}
              </p>
              <img src={officeImg} alt="kontor-img" className="kbc-icon" />
            </div>
            <div className="total-employees">
              <div>Areal:</div>
              <div>
                {perAreaData.totalValue && perAreaData.totalValue !== 0
                  ? formatReturnValue(perAreaData.totalValue)
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="key-result-footnote">
        * Oppgitt i tonn CO<sub>2</sub>-ekvivalenter
      </p>
    </ChartContainer>
  );
};

export default KeyBusinessContainer;
