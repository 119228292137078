import React, { useEffect, useRef } from "react";
import "./SideMenu.scss";
import kp_logo from "../static/kp_logo.svg";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import config from "../config/index.js";
import Icon from "./Icon";

const SideMenu = ({ location, showMenu, setShowMenu }) => {
  const urlGroupId = location.pathname.split("/");
  const ref = useRef(null);

  useEffect(() => {
    const listener = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", listener);

    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [ref, setShowMenu]);
  return (
    <div className={["sidemenu-container", showMenu ? "open" : ""].join(" ")}>
      <div ref={ref} className="sidemenu-bg">
        <div>
          <img src={kp_logo} alt="klimapartnere logo" className="kp_logo" />
        </div>
        {config.regioner.map((region, idx) => {
          return (
            <div
              className={[
                "menu-container",
                // eslint-disable-next-line eqeqeq
                urlGroupId[1] == region.groupid ? "active" : "",
              ].join(" ")}
              key={`${region.url}_${idx}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Link
                to={"/" + config.APIdescription[region.url] + "/" + region.url}
                key={`${region.url}_${idx}`}
                className="menu-title"
              >
                {region.logo && (
                  <span className="region-logo">
                    <Icon className="menu-img" name={region.logo} />
                  </span>
                )}
                <div className="region-title">{region.name}</div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(SideMenu);
