import { format, formatDefaultLocale } from "d3";

const no = {
  decimal: ",",
  thousands: " ", // \u00a0 or &nbsp; breaks svg download
  grouping: [3],
  currency: ["", "kr"],
};

formatDefaultLocale(no);

export function formatNumber(n) {
  if (typeof n !== "number" || isNaN(n)) return "NaN";

  if (Math.abs(n) < 0.00001 && (n !== 0 || n !== 0.0)) return format(",.2f")(n);

  if (n > 100) return format(",.0f")(n);

  return format(`,.1f`)(n);
}

export function formatReturnValue(value) {
  const v = value < 1 ? format(",.1f")(value) : formatNumber(value);

  if (value >= 1) return v;

  if (v === "Infinity") return "∞";

  return v;
}
