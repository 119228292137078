import React, { useState, useRef, useEffect, useCallback } from "react";
import Icon from "./Icon.js";
import LoadingDots from "./LoadingDots";
import useCompanies from "../hooks/useCompanies";
import { Link } from "react-router-dom";

import "./BusinessDropdown.scss";

const BusinessDropdown = ({ groupId, titlePage, orgnumber, year }) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [companyList, loading] = useCompanies(groupId, year);
  const ref = useRef(null);

  const listener = useCallback((e) => {
    if (!ref.current?.contains(e.target)) {
      setDisplayMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", listener);

    return () => document.removeEventListener("click", listener);
  }, [ref, displayMenu, listener]);

  return (
    <div ref={ref} className="dropdown-container">
      <button
        className="company-btn-dropdown"
        onClick={() => setDisplayMenu(!displayMenu)}
      >
        {loading ? <LoadingDots /> : orgnumber ? titlePage : "Velg bedrift"}
        {displayMenu ? (
          <Icon name="triangle_up" />
        ) : (
          <Icon name="triangle_down" />
        )}
      </button>

      {displayMenu && companyList && (
        <ul>
          {companyList.map((company, idx) => {
            return (
              <li key={idx}>
                <Link
                  className={"link-btn " + idx}
                  to={
                    "/" +
                    groupId +
                    "/" +
                    company.companyname +
                    "/" +
                    company.orgnumber
                  }
                  onClick={() => setDisplayMenu(false)}
                >
                  {company.companyname}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default BusinessDropdown;
