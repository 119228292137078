import React from "react";
import dompurify from "dompurify";
import ChartContainer from "./charts/ChartContainer.js";
import config from "../config/index.js";
import "./ClimateVisionText.scss";

const initialState = {
  title: "",
  text: "",
};

const ClimateVisionIconBox = ({ title, text, textContent }) => {
  const maalList =
    textContent &&
    Object.keys(textContent)
      .filter((res) => res.includes("maal"))
      .map(function (key, index) {
        return textContent[key];
      });

  return (
    <>
      {title.startsWith("Klimamål") ? (
        <ChartContainer
          className={
            "chartContainer" +
            ((maalList)
              ? ""
              : " inactive")
          }
          title={title || initialState.title}
        >
          <p className="climate-vision-subtitle">{text || initialState.text}</p>
          <div className="icon-text-outer-container">
            {config.iconList.map((maal, idx) => {
              return (
                <div className="icon-text-inner-container" key={idx}>
                  {maalList && maalList[idx] ? (
                    <img
                      className="cvt-icon"
                      src={config.iconList[idx]["green"]}
                      alt=""
                    />
                  ) : (
                    <img
                      className="cvt-icon"
                      src={config.iconList[idx]["orange"]}
                      alt=""
                    />
                  )}
                  <p className="climate-vision-iconTitle">
                    {config.iconList[idx]["title"]}
                  </p>
                </div>
              );
            })}
          </div>
        </ChartContainer>
      ) : (
        <ChartContainer title={title}>
          <div className="icon-text-outer-container transport">
            {config.transportIconList.map((d, idx) => {
              return (
                <div className="icon-text-inner-container transport" key={idx}>
                  <p className="climate-vision-subtitle transport">
                    {d.title || initialState.text}
                  </p>
                  <img src={d.img} className="cvt-icon" alt="car-icon" />
                  {d.text.map((text, idx) => {
                    var column = textContent && textContent[d.rowName[idx]];
                    return (
                      <div
                        className="climate-vision-transport-text-container"
                        key={idx}
                      >
                        <p
                          className={"climate-vision-transport-text top"}
                          dangerouslySetInnerHTML={{
                            __html: dompurify.sanitize(text),
                          }}
                        />
                        <p className={"climate-vision-transport-text bottom"}>
                          {" "}
                          {column !== null && column !== 0 ? column : "-"}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </ChartContainer>
      )}
    </>
  );
};

export default ClimateVisionIconBox;
