import React, { useState, useEffect, useMemo, useRef } from "react";
import { PieChart } from "avinet-dashboard-lib";
import ChartContainer from "../../components/charts/ChartContainer";
import KeyResultContainer from "../../containers/KeyResultContainer";
import TotalSumContainer from "../../components/TotalSumContainer";
import useCrossfilter from "../../hooks/useCrossfilter";
import dc from "dc";
import * as d3 from "d3";

import "./PDFChartContainer.scss";

const PDFChartContainer = ({ data, toggleCheckedFrivillig }) => {
  const [pieChartDim, setPieChartDim] = useState();
  const cf = useCrossfilter(data, "pdfCharts");
  const chartRef = useRef();

  // PieChart
  useEffect(() => {
    if (!cf) return;

    const dimension = cf.dimension((d) => d.kategori_navn);
    setPieChartDim(dimension);

    return () => dimension.dispose();
  }, [cf]);

  const pieChartGroup = useMemo(() => {
    if (!pieChartDim) return;

    return pieChartDim
      .group()

      .reduceSum((d) => Math.round(d.utslipp_year2 / 1000));
  }, [pieChartDim]);

  return (
    <div className="PDFChart-container-content">
      {data && (
        <div className="pdf-key-result-div">
          <KeyResultContainer
            data={data}
            className="pdf-key-result-div"
            pdfTitle={
              data.length > 0 ? "Nøkkelresultat" + data.year2 : "Nøkkelresultat"
            }
            toggleCheckedFrivillig={toggleCheckedFrivillig}
          />
        </div>
      )}
      <ChartContainer title="Samlet klimaregnskap" className="">
        {pieChartDim && (
          <div className="pdf-pie-chart-div">
            <PieChart
              ref={chartRef}
              className="piechart"
              group={pieChartGroup}
              dimension={pieChartDim}
              circularLegend
              minAngleForLabel={0.5}
              externalLabels={-40}
              slicesCap={25}
              height={350}
              radius={350}
              colors={d3
                .scaleOrdinal()
                .range([
                  "#c6dbef",
                  "#c7e9c0",
                  "#e6550d",
                  "#6baed6",
                  "#fdae6b",
                  "#9e9ac8",
                  "#31a354",
                  "#bcbddc",
                  "#756bb1",
                  "#dadaeb",
                  "#a1d99b",
                  "#003e74",
                  "#fdd0a2",
                  "#74c476",
                  "#9ecae1",
                  "#fd8d3c",
                ])
                .domain([
                  "Andre reiser (tog/buss/taxi)",
                  "Avfall til gjenvinning",
                  "Bygg (frivillig)",
                  "Drivstoff (kjøretøy og annet forbruk)",
                  "Elektrisitet",
                  "Fjernvarme/-kjøling",
                  "Flyreiser",
                  "Fyringsolje",
                  "Km-godtgjørelse",
                  "Pendling",
                  "Propan/natur-/lystgass",
                  "Prosessutslipp",
                  "Reise og transport (frivillig)",
                  "Restavfall til forbrenning",
                  "Tjenester (frivillig)",
                  "Varer (frivillig)",
                ])}
              showLegend
              // margins={[10, 0, 50, 0]}
              label={() =>
                chartRef.current &&
                chartRef.current.chart.on("pretransition", (chart) => {
                  chart
                    .selectAll("text.pie-slice")
                    .text((d) =>
                      dc.utils.printSingleValue(
                        ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100
                      ) < 10
                        ? ""
                        : dc.utils.printSingleValue(
                            ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100
                          ) + "%"
                    );
                })
              }
            />
            {/* hacky way to get ref working, will need to improve this */}
            <div style={{ height: 0, overflow: "hidden" }}>
              <PieChart
                ref={chartRef}
                className="piechart"
                group={pieChartGroup}
                dimension={pieChartDim}
                height={250}
                radius={250}
                colors={d3
                  .scaleOrdinal()
                  .range([
                    "#c6dbef",
                    "#c7e9c0",
                    "#e6550d",
                    "#6baed6",
                    "#fdae6b",
                    "#9e9ac8",
                    "#31a354",
                    "#bcbddc",
                    "#756bb1",
                    "#dadaeb",
                    "#a1d99b",
                    "#003e74",
                    "#fdd0a2",
                    "#74c476",
                    "#9ecae1",
                    "#fd8d3c",
                  ])
                  .domain([
                    "Andre reiser (tog/buss/taxi)",
                    "Avfall til gjenvinning",
                    "Bygg (frivillig)",
                    "Drivstoff (kjøretøy og annet forbruk)",
                    "Elektrisitet",
                    "Fjernvarme/-kjøling",
                    "Flyreiser",
                    "Fyringsolje",
                    "Km-godtgjørelse",
                    "Pendling",
                    "Propan/natur-/lystgass",
                    "Prosessutslipp",
                    "Reise og transport (frivillig)",
                    "Restavfall til forbrenning",
                    "Tjenester (frivillig)",
                    "Varer (frivillig)",
                  ])}
                label={() =>
                  chartRef.current &&
                  chartRef.current.chart.on("renderlet", (chart) => {
                    chart
                      .selectAll("text.pie-slice")
                      .text((d) =>
                        dc.utils.printSingleValue(
                          ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100
                        ) < 10
                          ? ""
                          : dc.utils.printSingleValue(
                              ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
                                100
                            ) + "%"
                      );
                  })
                }
              />
            </div>
          </div>
        )}
      </ChartContainer>

      {data && data.length > 0 && (
        <TotalSumContainer
          data={data}
          toggleCheckedFrivillig={toggleCheckedFrivillig}
          PDF={true}
        />
      )}
    </div>
  );
};

export default PDFChartContainer;
