import React from "react";
import pdf from "../../static/pdf_img.png";
import "./PDFButton.scss";

const PDFButton = ({ showPdfPopup }) => {
  return (
    <button className="pdf-btn" onClick={showPdfPopup}>
      <img alt="pdf-logo" className="pdf-img" src={pdf} />
      Hent PDF
    </button>
  );
};

export default PDFButton;
