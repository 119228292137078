import React, { useState, useEffect, useMemo, useRef } from "react";
import ChartContainer from "./ChartContainer";
import dc from "dc";
import * as d3 from "d3";
import { PieChart } from "avinet-dashboard-lib";
import "./PieChartContainer.scss";
import { formatNumber } from "../../utils/number";

const PieChartContainer = ({ activeCrossfilter }) => {
  const [dim, setDim] = useState();
  const chartRef = useRef();

  useEffect(() => {
    if (!activeCrossfilter) return;

    const dimension = activeCrossfilter.dimension((d) => d.kategori_navn);
    setDim(dimension);

    return () => dimension.dispose();
  }, [activeCrossfilter]);

  const group = useMemo(() => {
    if (!dim) return;

    return dim.group().reduceSum((d) => d.utslipp_year2 / 1000);
  }, [dim]);

  return (
    <ChartContainer title="Samlet klimaregnskap">
      <p className="climate-vision-subtitle">
        Samlet klimaregnskap oppgitt i tonn CO<sub>2</sub>-ekvivalenter. Før
        musepeker over kakedeler for å se eksakte data. Oversikten til høyre
        vises fra høyeste utslipp til laveste.
      </p>
      {dim && (
        <PieChart
          ref={chartRef}
          className="piechart"
          group={group}
          radius={400}
          height={400}
          dimension={dim}
          circularLegend
          minAngleForLabel={0.5}
          externalLabels={-40}
          slicesCap={25}
          showLegend
          margins={[10, 0, 50, 0]}
          renderCustomTitle={true}
          customTitleFormat={(d) => `${d.key}: ${formatNumber(d.value)}`}
          colors={d3
            .scaleOrdinal()
            .range([
              "#c6dbef",
              "#c7e9c0",
              "#e6550d",
              "#6baed6",
              "#fdae6b",
              "#9e9ac8",
              "#31a354",
              "#bcbddc",
              "#756bb1",
              "#dadaeb",
              "#a1d99b",
              "#003e74",
              "#fdd0a2",
              "#74c476",
              "#9ecae1",
              "#fd8d3c",
            ])
            .domain([
              "Andre reiser (tog/buss/taxi)",
              "Avfall til gjenvinning",
              "Bygg (frivillig)",
              "Drivstoff (kjøretøy og annet forbruk)",
              "Elektrisitet",
              "Fjernvarme/-kjøling",
              "Flyreiser",
              "Fyringsolje",
              "Km-godtgjørelse",
              "Pendling",
              "Propan/natur-/lystgass",
              "Prosessutslipp",
              "Reise og transport (frivillig)",
              "Restavfall til forbrenning",
              "Tjenester (frivillig)",
              "Varer (frivillig)",
            ])}
          label={() => {
            chartRef.current &&
              chartRef.current.chart.on("renderlet", (chart) => {
                chart.selectAll("text.pie-slice").text((d) => {
                  const percent = (
                    ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
                    100
                  ).toFixed(1);
                  return dc.utils.printSingleValue(percent) < 6
                    ? ""
                    : dc.utils.printSingleValue(percent) + "%";
                });
              });
          }}
        />
      )}
    </ChartContainer>
  );
};

export default PieChartContainer;
