import React from "react";
import "./CheckBox.scss";

export const CheckBox = (props) => {
  const { onCheckChange, checked, label } = props;

  return (
    <label className="checkbox--wrapper">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => onCheckChange(!checked)}
      />
      <span className="checkbox" />
      <span className="checkbox-text">{label}</span>
    </label>
  );
};
