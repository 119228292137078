import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config/index";

export default function useTextFromAPI(isNational, isOrganisation, id, year) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id === "-" || isOrganisation) {
      setData(null);
      return;
    }

    setData(null);
    setLoading(true);

    const req = isNational
      ? request.get(`${config.apiUrl}/getNetworkData`)
        .query({ networkid: 1 })
        .query({ year: year })
      : request.get(`${config.apiUrl}/getGroupData`)
        .query({ groupid: id })
        .query({ year: year });

    req
      .then((res) => res.body.data)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setData(null);
        setLoading(false);
        console.warn("Request failed", err);
      });

    return () => req.abort();
  }, [year, id, isNational, isOrganisation]);

  return [data, loading];
}
