import React from "react";
import "./App.scss";
import "./style/theme.scss";
import Router from "./router";

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
