import React, { useMemo } from "react";
import config from "../config/index";
import ChartContainer from "./charts/ChartContainer";

const AggregatedClimateVision = ({
  textContent,
  totalCompaniesInRegion,
  isNational,
  className,
}) => {
  const maalList = useMemo(() => {
    if (!textContent) return;

    return Object.keys(textContent)
      .filter((res) => res.includes("maal"))
      .map(function (key) {
        return textContent[key];
      });
  }, [textContent]);

  return (
    <ChartContainer title="Klimamål" className={className}>
      <p className="climate-vision-subtitle">
        {isNational
          ? "Antall partnere nasjonalt som har satt seg gjeldende mål, har etablert gjeldende plan eller oppnådd miljøsertifisering"
          : "Antall partnere i region som har satt seg gjeldende mål, har etablertgjeldende plan eller oppnådd miljøsertifisering."}
      </p>
      <div className="icon-text-outer-container">
        {config.iconList.map((maal, idx) => {
          return (
            <div className="icon-text-inner-container" key={idx}>
              {maalList && maalList[idx] ? (
                <img
                  className="cvt-icon"
                  src={config.iconList[idx]["green"]}
                  alt=""
                />
              ) : (
                <img
                  className="cvt-icon"
                  src={config.iconList[idx]["orange"]}
                  alt=""
                />
              )}
              <p className="climate-vision-iconTitle">
                {config.iconList[idx]["title"]}:{<br />}
                {maalList[idx] ? maalList[idx] : 0} / {totalCompaniesInRegion}
              </p>
            </div>
          );
        })}
      </div>
    </ChartContainer>
  );
};

export default AggregatedClimateVision;
