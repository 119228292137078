import React from "react";
import Loader from "../static/loading.svg";
import "./LoadMask.scss";

const LoadMask = ({ loading }) => {
  return loading ? (
    <div className="loader">
      <img src={Loader} alt="loading" />
    </div>
  ) : (
    <React.Fragment />
  );
};

export default LoadMask;
